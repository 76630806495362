export type ClusterTableHeaderColumn = {
  key: string;
  label: string;
};

export const CLUSTER_MAINTABLE_COLUMNS = [
  { key: "no", label: "#" },
  { key: "cluster", label: "클러스터" },
  { key: "totalCount", label: "배송지수" },
  { key: "totalBox", label: "총 박스" },
  { key: "totalTime", label: "총 소요시간" },
  { key: "totalDistance", label: "총 이동거리" },
];

export type ClusterMaintableRow = {
  [key in (typeof CLUSTER_MAINTABLE_COLUMNS)[number]["key"]]: string;
};

export const CLUSTER_SUBTABLE_COLUMNS = [
  { key: "clusterKey", label: "클러스터" },
  { key: "pointType", label: "배송순서" },
  { key: "clientName", label: "고객명" },
  { key: "address", label: "주소" },
  { key: "box", label: "박스" },
  { key: "distance", label: "이동 거리" },
  { key: "latitude", label: "위도" },
  { key: "longitude", label: "경도" },
  { key: "completeRequestTimeLower", label: "특정시간 이후도착" },
  { key: "completeRequestTimeUpper", label: "특정시간 이전도착" },
  { key: "arrivalTime", label: "구간 별 도착 예정시간" },
  { key: "withinTimeWindow", label: "타임윈도우 충족 여부" },
] as const;

export type ClusterSubtableRow = {
  [key in (typeof CLUSTER_SUBTABLE_COLUMNS)[number]["key"]]: string;
};
