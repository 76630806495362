import { useState } from "react";
import ClusterUploadContainer from "./ClusterUploadContainer";
// import Json from "./tmap_response3.json";
import ClusteringTestbed from "../../components/cluster/ClusteringTestbed";
import {
  ClusterResponse,
  SenderType,
  DEFAULT_CLUSTER_SENDER,
} from "../../components/cluster/types";

function ClusterContainer() {
  const [clustering, setClustering] = useState<ClusterResponse | null>(null);
  const [senderInfo, setSenderInfo] = useState<SenderType>(
    DEFAULT_CLUSTER_SENDER
  );

  return (
    <>
      {clustering == null ? (
        <ClusterUploadContainer
          setClustering={setClustering}
          setSenderInfo={setSenderInfo}
          senderInfo={senderInfo}
        />
      ) : (
        <ClusteringTestbed clustering={clustering} senderInfo={senderInfo} />
      )}
    </>
  );
}

export default ClusterContainer;
