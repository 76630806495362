import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Snackbar,
} from "@material-ui/core";
import styled from "styled-components";
import ProjectModifyModal from "./ProjectModifyModal";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import useSnackbar from "../../hooks/useSnackbar";
import Notice from "../../components/common/Notice";
import {
  CorporateProject,
  DEFAULT_PROJECT,
  headRows,
} from "../../containers/corporation/types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { getProjects } from "../../lib/hasura/departments";
import { destructResponse } from "../../lib/hasura/common";
import { stableSort, getSorting } from "../dataTable/base";
import { CustomLegend } from "../common/Base";
import Paper from "../common/Paper";

type SortableProjectRow = CorporateProject & {
  [K in Exclude<(typeof headRows)[number]["id"], keyof CorporateProject>]:
    | string
    | number
    | boolean;
};

function ProjectsList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const corpId = history.location.pathname.replace("/corporation/", "");

  const [projects, setProjects] = useState<CorporateProject[]>([]);
  const [project, setProject] = useState<CorporateProject>(DEFAULT_PROJECT);
  const [modalOpen, setModalOpen] = useState(false);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof SortableProjectRow | "">("");

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // "프로젝트 관리" 버튼을 눌렀을 때 프로젝트 데이터들을 fetch한다.
  const fetchProjects = async () => {
    dispatch(startGlobalLoading());
    try {
      const result = await destructResponse<CorporateProject[]>(
        "corporations_project",
        () => getProjects(corpId)
      );
      setProjects(result);
    } catch (error) {
      openSnackbar("프로젝트 정보를 받아올 수 없습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchProjects();
  }, [corpId]);

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
  };

  const handleRequestSort = (
    e: React.MouseEvent,
    property: keyof SortableProjectRow
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  // 프로젝트 상세정보 모달
  const modalRef = useRef<HTMLDivElement>(null);
  const openProjectModal = async (row: CorporateProject) => {
    setProject(row);
    setModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeProjectModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "";
  };
  const outSideModal = (e: React.MouseEvent) => {
    if (modalRef.current === e.target) {
      setModalOpen(false);
      document.body.style.overflow = "";
    }
  };

  const sortableProjectsRows: SortableProjectRow[] = projects.map((row) => ({
    ...row,
    corporation_sort: row.corporations_corporation.name,
  }));

  return (
    <>
      <CustomPaper>
        <CustomLegend>프로젝트 관리 ({projects.length})</CustomLegend>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headRows={headRows}
          />
          <TableBody>
            {stableSort(
              sortableProjectsRows,
              getSorting<SortableProjectRow>(order, orderBy)
            ).map((row: CorporateProject) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  onClick={() => openProjectModal(row)}
                >
                  <TableCell>{row.corporations_corporation.name}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.limit_budget}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CustomPaper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
      {modalOpen && (
        <ProjectModifyModal
          row={project}
          setProject={setProject}
          fetchProjects={fetchProjects}
          modalRef={modalRef}
          outSideModal={outSideModal}
          closeProjectModal={closeProjectModal}
          openSnackbar={openSnackbar}
        />
      )}
    </>
  );
}

export default ProjectsList;

const CustomPaper = styled(Paper)`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;
