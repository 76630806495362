import { http, devHttp } from "./instance";

/**
 * @description 인성 데이터 조회
 * - 해당 url은 createProxyMiddleware target에 설정
 * - 현재 changeOrigin: false로 비활성화
 */
export const insungDataAPI = async (data) => {
  const url = "/api/order_detail/";
  try {
    return await http.get(url, {
      params: {
        m_code: "7073",
        cc_code: "11829",
        user_id: "79366087",
        token: "7e7d4861613d4bd394a85d8f076fbc5c",
        serial: data[0],
        type: "json",
      },
    });
  } catch (error) {
    console.log(error.response);
  }
};

/**
 * @description 대량 요금 조회
 */
export const downloadCharge = async (channel, data) => {
  const url = "/algoquick/bulk-order/download_charge";
  return await http.post(url, { channel, data });
};

/**
 * @description 인성 주문 재성성
 * - 인성 오류 주문 재생성 요청
 * @param { object} data 재요청 업데이트 데이터
 * @param { string} data.channel 채널
 * @param { string} data.client_name 고객 이름
 * @param { string} data.sender_name 보내는 사람 이름
 * @param { string} data.receiver_name 받는 사람 이름
 * @param { string} data.receiver_address 받는 사람 주소
 * @param { string} data.receiver_jibun_address 받는 사람 상세 주소
 * @param { string} data.memo 주문 메모
 */
export const reOrder = async (data) => {
  const url = `/order/re-order/${data.id}`;
  return http.patch(url, {
    channel: data.channel,
    client_name: data.client_name,
    sender_name: data.sender_name,
    receiver_name: data.receiver_name,
    receiver_address: data.receiver_address,
    receiver_jibun_address: data.receiver_jibun_address,
    memo: data.memo,
  });
};

/**
 * @description 주문 업데이트 (인성 주문 번호), 카카오톡 전송 X
 * - 백엔드에 인성주문 번호로 주문을 생성하거나, 업데이트 요청
 * @param { string } isdOrderNumber 이벤트
 */
export const syncOrder = async (isdOrderNumber) => {
  const url = `/order/sync-order/${isdOrderNumber}`;
  return http.get(url);
};

/**
 * @description 지방 인성번호 접수
 * @param { string } orderId 알고퀵 주문번호
 */
export const getExternalOrder = async (orderId) => {
  const url = `/algoquick/order/${orderId}/create-isd-external`;
  return http.get(url);
};
/**
 * @description 주문 업데이트 (인성 주문 번호), 카카오톡 전송 X
 * - 백엔드에 인성주문 번호로 주문을 생성하거나, 업데이트 요청
 * @param { string } isdOrderNumber 이벤트
 */
export const importOrder = async (isdOrderNumber) => {
  const url = `/order/import-order/${isdOrderNumber}`;
  return http.get(url);
};

/**
 * @description 주문 업데이트 (날짜별)
 * - 백엔드에 날짜별 스케줄러 작동을 요청
 * @param { string } start 시작일
 * @param { string } end 종료일
 */
export const rangeUpdateOrder = (start, end) => {
  const url = `/order/manual-order-scheduler/${start}/${end}`;
  return http.get(url);
};

/**
 * @description 주문 업데이트 (날짜별)
 * - 백엔드에 날짜별 스케줄러 작동을 요청
 * @param { object } kakao 카카오톡 관련 object
 * @param { string } kakao.id 주문 아이디
 * @param { string } kakao.kakao_phone_number 휴대폰 번호
 * @param { string } kakao.kakao_status 주문 상태
 */
export const sendKakao = (kakao) => {
  const url = `/order/send-kakao?id=${kakao.id}&kakao_phone_number=${kakao.kakao_phone_number}&kakao_status=${kakao.kakao_status}`;
  return http.get(url);
};

/**
 * @description 묶음 배송 요청
 * @param { object } data 묶음 배송 object
 */
export const bundleOrders = (data) => {
  const url = "/order/bundle";
  return http.post(url, data);
};

/**
 * @description 벡엔드에 해당 ID로 전국24시콜화물 주문접수 요청
 * @param { object } call24 전국24시콜화물
 */
export const call24Api = async (call24) => {
  const url = "/order/call24";
  return http.post(url, call24);
};

/**
 * @description 카드 웹접수 오더 카드 취소, 주문 취소
 * @param { object } card  웹 카드 주문
 * @param { string } card.id 주문 아이디
 * @param { string } card.tid 카드 tid
 * @param { string } card.orderCharge 주문 총 금액
 * @param { string } card.charge 주문 취소 금액
 * @param { string } card.cancelMessage 주문 취소 메세지
 * @param { string } card.password 취소 패스워드
 * @param { string } card.code 부분 취소 여부 0: 전체취소, 1: 부분취소
 */
export const cancelCardApi = async (card) => {
  const url = "/order/cancel-card";
  return http.post(url, card);
};

export const repaymentCardApi = async (orderId) => {
  const url = `/order/cancel-card/${orderId}`;
  return http.get(url);
};

/**
 * @description 인성 주문 번호 swap, 두 주문 업데이트 요청
 * @param { string } isdOrderNumber 기존 인성 주문 번호
 * @param { string } swapIsdOrderNumber swap 인성 주문 번호
 */
export const swapOrder = async (isdOrderNumber, swapIsdOrderNumber) => {
  const url = `/order/swap-order/${isdOrderNumber}/${swapIsdOrderNumber}`;
  return http.get(url);
};

// export const postExcelOrders = (excelJSONData) => {
//   const url = `/algoquick/bulk-order/bulk_input`;

//   try {
//     return http.post(url, excelJSONData);
//   } catch (error) {
//     console.log("error", error);
//   }
//   // console.log("excelJSONData", excelJSONData);
//   // return new Promise((resolve, reject) => {
//   //   setTimeout(() => {
//   //     resolve({
//   //       data: { success: [0, 2], fail: [1] },
//   //     });
//   //   }, 3000);
//   // });
// };

export const postExcelOrders = async (excelJSONData) => {
  const url = "/algoquick/bulk-order/bulk_input";

  let failList = [];
  let successList = [];
  let convertedData = {};
  const tenCount = Math.floor(excelJSONData.length / 10);

  for (let i = 0; i <= tenCount; i++) {
    const fetchData = await http
      .post(url, excelJSONData.slice(i * 10, (i + 1) * 10))
      .then((res) => res && res.data)
      .catch((err) => console.dir(err));

    let failToArray = Object.values(fetchData.fail); // []
    let successToArray = Object.values(fetchData.success); // ["AQMSZW002422", "AQMSZW002522"]

    failList = [...failList, ...failToArray]; // []
    successList = [...successList, ...successToArray]; // ["AQMSZW002422", "AQMSZW002522", "AQMSZW002422", "AQMSZW002522"]
  }

  convertedData = {
    fail: Object.assign({}, Object.values(failList)), // {}
    success: Object.assign({}, Object.values(successList)), // {0: "AQMSZW002422", 1: "AQMSZW002522", 2: "AQMSZW002422", 3: "AQMSZW002522"}
  };
  return convertedData;
};

export const getJibunAddress = (excelJSONData) => {
  const url = "/algoquick/bulk-order/address";
  return http.post(url, excelJSONData);
};

export const getLatLngData = async (data) =>
  await devHttp.post("/algoquick/bulk-order/get_latlon", { data });

export const getObpCluster = async (data) =>
  await devHttp.post("/ai/obp", data).then(({ data }) => data);

export const getClusterResult = async (data) =>
  await devHttp.post("/algoquick/cluster/result", data);

export const getKeywordAddressSearchList = async (
  query,
  page = 1,
  size = 10
) => {
  const url = "/local/keyword";
  return await http.get(url, { params: { query, page, size } });
};

export const getJibunAddressSearchList = async (query, page = 1, size = 10) => {
  const url = "/local/address";
  return await http.get(url, {
    params: { query, page, size },
  });
};

export const postSendBillExcel = (excelJSONData) => {
  const url = process.env.REACT_APP_SENDBILL_URL;

  return http.post(url, excelJSONData);
};

export const postSquareBundleOrder = async (squareBundleData) => {
  const url = process.env.REACT_APP_SQUARE_BUNDLE_ORDER;
  const { data } = await http.post(url, squareBundleData);
  /* 
[
[
  [
    "37.5074454982119",
    "127.034284713238",
    "서울 강남구 봉은사로 지하 201"
  ]
]
[
  [
    "37.5045028775835",
    "127.048942471228",
    "서울 강남구 테헤란로 지하 340"
  ],
  [
    "37.5014620128395",
    "127.048872178861",
    "서울 강남구 역삼로 313"
  ]
]]

*/
  // const { data } = await new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: [
  //         31.176914,
  //         [
  //           [
  //             [37.4716393313279, 127.026728803944, "양재주민센터"],
  //             [37.4852951142482, 127.104469788175, "수서역"],
  //             [37.5054141216925, 127.107004062699, "석촌역"],
  //           ],
  //           [
  //             [37.4765604303289, 126.981558584016, "사당역"],
  //             [37.5509646154307, 126.849533759513, "강서구청"],
  //             [37.4783683761407, 126.951561853867, "관악구청"],
  //           ],
  //           [
  //             [37.5582396348315, 127.000398385089, "동국대학교"],
  //             [37.5760299683175, 127.024456700382, "신설동역 2호선"],
  //           ],
  //         ],
  //       ],
  //     });
  //   }, 3000);
  // });

  return data;
};

export async function sendTextMessage({ phone, allocateMessage }) {
  if (!phone || !allocateMessage) return;

  const url = "/user/sms";
  const body = {
    phone,
    allocateMessage,
  };

  try {
    await http.post(url, body);
  } catch (error) {
    alert("🚨 문자메시지 전송실패 새로고침 후 다시시도 해주세요");
    console.log(error);
  }
}

// originTextMessage는 originSMS를 유지 => 수신번호 : 010-4040-7442
// textMessage => originSMS + 실제 내용이 들어갈듯

export const patchCall24Receipt = async (id, { url }) => {
  try {
    await http.patch(`/order/call24/receipt/${id}`, { url });
  } catch (error) {
    const { response: { data } = {} } = error;
    throw new Error(data?.detail || error.message);
  }
};
