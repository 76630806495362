import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import CorporationRegistList from "./CorporationRegistList";
import { UserState } from "../../containers/user/types";
import { DepartState } from "../../containers/department/types";
import { ColumnDivider, FlexBox, CustomLegend } from "../common/Base";
import ExcelFileDownload from "../../lib/excel/ExcelFileDownload";

// 법인관리 엑셀 업로드 공통 컴포넌트 (부서등록, 회원등록, 프로젝트등록)
interface CorporationRegistProps {
  upload: boolean;
  fileName: string;
  data: DepartState[] | UserState[];
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  regist: "depart" | "user" | "project";
  download?: () => void;
}

const headName = {
  depart: "부서",
  user: "회원",
  project: "프로젝트",
};

function CorporationRegist({
  upload,
  fileName,
  handleUpload,
  data,
  onSubmit,
  regist,
  download,
}: CorporationRegistProps) {
  const sampleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const link = document.createElement("a");

    if (regist === "user") {
      link.href =
        "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/algoquick_user_231212.xlsx";
    } else {
      // 부서, 프로젝트는 동일한 양식
      link.href =
        "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/algoquick_depart_231212.xlsx";
    }

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const name = headName[regist];

  return (
    <Wrapper>
      <CustomLegend>{`${name} 등록`}</CustomLegend>
      <FlexBox>
        <Button
          variant="outlined"
          color="default"
          size="large"
          startIcon={<CloudDownload />}
          onClick={sampleDownload}
        >
          {`${name}등록 양식 다운로드`}
        </Button>
        <ColumnDivider />
        <label htmlFor="contained-button-file">
          <Button
            variant="outlined"
            color="default"
            component="span"
            size="large"
            startIcon={<CloudUpload />}
          >
            {`${name}등록 (업로드)`}
          </Button>
        </label>
        <ColumnDivider />
        {download ? (
          <>
            <label htmlFor="custom-downloader">
              <ExcelFileDownload
                isIcon={true}
                buttonName={`${name} 내역 다운로드`}
                onClick={download}
              />
            </label>
          </>
        ) : null}
        <SearchInput
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="contained-button-file"
          type="file"
          onChange={handleUpload}
          style={{ display: "none" }}
        />
        {upload && fileName !== "" ? (
          <>
            <FileNameBox>파일이름: {fileName}</FileNameBox>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={onSubmit}
            >
              {`${name}등록 진행`}
            </Button>
          </>
        ) : null}
      </FlexBox>
      {upload && fileName !== "" && (
        <CorporationRegistList data={data} regist={regist} />
      )}
    </Wrapper>
  );
}

export default CorporationRegist;

const Wrapper = styled.div`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
`;

const SearchInput = styled.input``;

const FileNameBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 0 1.5rem;
  font-weight: 800;
  background: aliceblue;
`;
