import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Input,
  InputAdornment,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { format } from "date-fns";

import InputTimeForm from "./InputTimeForm";
import VehicleForm from "./VehicleForm";

import {
  CHANGE_RECEIVER_ADDRESS,
  GET_ADDRESS_SEARCH_LIST,
  RESET_ADDRESS_SEARCH_LIST,
} from "../../modules/groupOrder";

import { checkVehecleTypeIsTruck } from "../../pages/GroupOrder/utils";

interface AddressDialogProps {
  dialogState: any;
  handleDialog: any;
  data: any;
}

const DialogText = styled(DialogContentText)`
  margin-top: 10px !important;
  color: #000 !important;
  font-weight: bold !important;
`;

type datePickerChangeType = "pickupRequestTime" | "completeRequestTime";
type completeRequestTimeType = "upper" | "lower";

function ReceiverDialog({
  dialogState,
  handleDialog,
  data,
}: AddressDialogProps) {
  const dispatch = useDispatch();

  const {
    state: isSucceed,
    data: addressSearchList,
    noData,
  } = useSelector(
    ({ groupOrder }: RootStateOrAny) =>
      groupOrder.result.getAddressSearchList.success
  );
  const { index, open } = dialogState;

  const {
    senderName,
    senderAddress,
    senderJibunAddress,
    senderAddressDetail,
    senderPhoneNumber,
    receiverName,
    receiverAddress,
    receiverJibunAddress,
    receiverAddressDetail,
    receiverPhoneNumber,
    deliveryItem,
    charge,
    vehicleType,
    vehicleOption,
    truckOption,
    truckWeight,
    isLift,
    memo,
    pickupRequestTime,
    completeRequestTime,
  } = data[index];

  const [receiverInfo, setReceiverInfo] = useState({
    senderJibunAddress,
    senderAddressDetail,
    receiverJibunAddress,
    receiverAddressDetail,
    deliveryItem,
    charge,
    vehicleType,
    vehicleOption,
    truckOption,
    truckWeight,
    isLift: isLift ? isLift : false,
    memo,
    pickupRequestTime,
    completeRequestTime,
  });

  // 출발지
  const [senderSearch, setSenderSearch] = useState("");
  const [senderListOpen, setSenderListOpen] = useState(false);

  // 도착지
  const [addressSearch, setAddressSearch] = useState("");
  const [isListOpen, setListOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: RESET_ADDRESS_SEARCH_LIST });
  }, [open, dispatch, noData]);

  useEffect(() => {
    if (noData) {
      alert("주소검색 결과가 없습니다!");
    }
  }, [noData]);

  const handleSenderText = (e: any) => {
    setSenderSearch(e.target.value);
  };
  const handleSearchText = (e: any) => {
    setAddressSearch(e.target.value);
  };

  const senderAddressSearch = (query: string) => (e: any) => {
    e.preventDefault();
    if (query === "") {
      alert("검색어를 입력해주세요!");
      return;
    }
    dispatch({ type: GET_ADDRESS_SEARCH_LIST, payload: query });
    setSenderListOpen(true);
  };
  const queryAddressSearch = (query: string) => (e: any) => {
    e.preventDefault();
    if (query === "") {
      alert("검색어를 입력해주세요!");
      return;
    }
    dispatch({ type: GET_ADDRESS_SEARCH_LIST, payload: query });
    setListOpen(true);
  };

  const handleReceiverInfo = (key: string) => (e: any) => {
    setReceiverInfo({ ...receiverInfo, [key]: e.target.value });
  };

  const handleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReceiverInfo({ ...receiverInfo, isLift: e.target.checked });
  };

  const handleReceiverTime =
    (type: datePickerChangeType, sort?: completeRequestTimeType) =>
    (date: string | number) => {
      if (!date) return;

      if (type === "completeRequestTime") {
        const completeRequestTime = {
          ...receiverInfo.completeRequestTime,
          [sort as completeRequestTimeType]: format(
            new Date(date),
            "yyyy-MM-dd HH:mm"
          ),
        };
        setReceiverInfo({
          ...receiverInfo,
          completeRequestTime,
        });

        return;
      }

      setReceiverInfo({
        ...receiverInfo,
        [type]: format(new Date(date), "yyyy-MM-dd HH:mm"),
      });
    };

  const setSenderAddress = (addressInfo: any) => () => {
    const {
      address: senderJibunAddress,
      place_name,
      address_detail,
      address_name,
    } = addressInfo;
    let senderAddressDetail = "";

    if (place_name) {
      senderAddressDetail = senderAddressDetail + place_name;
    }

    if (address_detail) {
      senderAddressDetail = senderAddressDetail + address_detail;
    }

    if (address_name) {
      senderAddressDetail = senderAddressDetail + address_name;
    }

    setReceiverInfo({
      ...receiverInfo,
      senderJibunAddress,
      senderAddressDetail,
    });

    setSenderListOpen(false);
  };
  const setAddressInput = (addressInfo: any) => () => {
    const {
      address: receiverJibunAddress,
      place_name,
      address_detail,
      address_name,
    } = addressInfo;
    let receiverAddressDetail = "";

    if (place_name) {
      receiverAddressDetail = receiverAddressDetail + place_name;
    }

    if (address_detail) {
      receiverAddressDetail = receiverAddressDetail + address_detail;
    }

    if (address_name) {
      receiverAddressDetail = receiverAddressDetail + address_name;
    }

    setReceiverInfo({
      ...receiverInfo,
      receiverJibunAddress,
      receiverAddressDetail,
    });

    setListOpen(false);
  };

  const getVehicleOptions = () => {
    if (!checkVehecleTypeIsTruck(receiverInfo.vehicleType)) {
      return { vehicleOption: "", truckWeight: "", truckOption: "" };
    }

    if (!receiverInfo.truckWeight && !receiverInfo.truckOption) {
      return { vehicleOption: "", truckWeight: "", truckOption: "" };
    }

    return {
      vehicleOption: `${receiverInfo.truckWeight}${receiverInfo.truckOption}${
        receiverInfo.isLift ? "/LIFT" : ""
      }`,
      truckWeight: receiverInfo.truckWeight,
      truckOption: receiverInfo.truckOption,
    };
  };

  const changeAddress = () => {
    dispatch({
      type: CHANGE_RECEIVER_ADDRESS,
      payload: {
        index,
        ...receiverInfo,
        ...getVehicleOptions(),
      },
    });
    handleDialog(false)();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleDialog(false)}
        fullWidth
        maxWidth={"sm"}
        disableEnforceFocus
      >
        <DialogTitle>주소수정</DialogTitle>
        <DialogContent>
          <DialogText>
            출발지 이름 (연락처) : {senderName} ( {senderPhoneNumber} )
          </DialogText>
          <form onSubmit={senderAddressSearch(senderSearch)}>
            <Input
              id="standard-adornment-password"
              type="text"
              value={senderSearch}
              onChange={handleSenderText}
              fullWidth
              placeholder={"출발지 지번, 도로명, 건물명, 회사명을 검색하세요!"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="adress-search-icon"
                    type="submit"
                    onClick={senderAddressSearch(senderSearch)}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          {isSucceed && senderListOpen && !noData && (
            <List>
              {addressSearchList.map((addressInfo: any, index: number) => (
                <ListItem
                  button
                  key={index}
                  onClick={setSenderAddress(addressSearchList[index])}
                >
                  <ListItemText
                    primary={addressInfo.address}
                    secondary={
                      addressInfo.place_name ||
                      addressInfo.address_detail ||
                      addressInfo.address_name
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
          <TextField
            margin="dense"
            id="address"
            label="출발지 원래주소"
            multiline
            fullWidth
            disabled
            value={senderAddress}
          />
          <TextField
            margin="dense"
            id="jibunaddress"
            label="출발지 지번주소"
            multiline
            fullWidth
            value={receiverInfo.senderJibunAddress}
            onChange={handleReceiverInfo("senderJibunAddress")}
          />
          <TextField
            margin="dense"
            id="detailaddress"
            label="출발지 상세주소"
            multiline
            fullWidth
            value={receiverInfo.senderAddressDetail}
            onChange={handleReceiverInfo("senderAddressDetail")}
          />

          <DialogText>
            도착지 이름 (연락처) : {receiverName} ( {receiverPhoneNumber} )
          </DialogText>
          <form onSubmit={queryAddressSearch(addressSearch)}>
            <Input
              id="standard-adornment-password"
              type="text"
              value={addressSearch}
              onChange={handleSearchText}
              fullWidth
              placeholder={"도착지 지번, 도로명, 건물명, 회사명을 검색하세요!"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="adress-search-icon"
                    type="submit"
                    onClick={queryAddressSearch(addressSearch)}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
          {isSucceed && isListOpen && !noData && (
            <List>
              {addressSearchList.map((addressInfo: any, index: number) => (
                <ListItem
                  button
                  key={index}
                  onClick={setAddressInput(addressSearchList[index])}
                >
                  <ListItemText
                    primary={addressInfo.address}
                    secondary={
                      addressInfo.place_name ||
                      addressInfo.address_detail ||
                      addressInfo.address_name
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
          <TextField
            margin="dense"
            id="address"
            label="도착지 원래주소"
            multiline
            fullWidth
            disabled
            value={receiverAddress}
          />
          <TextField
            margin="dense"
            id="jibunaddress"
            label="도착지 지번주소"
            multiline
            fullWidth
            value={receiverInfo.receiverJibunAddress}
            onChange={handleReceiverInfo("receiverJibunAddress")}
          />
          <TextField
            margin="dense"
            id="detailaddress"
            label="도착지 상세주소"
            multiline
            fullWidth
            value={receiverInfo.receiverAddressDetail}
            onChange={handleReceiverInfo("receiverAddressDetail")}
          />
          <TextField
            margin="dense"
            id="deliveryItem"
            label="배송품"
            multiline
            fullWidth
            value={receiverInfo.deliveryItem}
            onChange={handleReceiverInfo("deliveryItem")}
          />
          <TextField
            margin="dense"
            id="charge"
            label="요금"
            type="number"
            fullWidth
            value={receiverInfo.charge}
            onChange={handleReceiverInfo("charge")}
          />
          <VehicleForm
            handleReceiverInfo={handleReceiverInfo}
            handleSwitch={handleSwitch}
            receiverInfo={receiverInfo}
          />
          <TextField
            margin="dense"
            id="memo"
            label="메모"
            multiline
            fullWidth
            value={receiverInfo.memo}
            onChange={handleReceiverInfo("memo")}
          />

          <InputTimeForm
            time={receiverInfo.pickupRequestTime}
            label={"픽업예약시간"}
            handleReceiverTime={handleReceiverTime("pickupRequestTime")}
          />

          <InputTimeForm
            time={receiverInfo.completeRequestTime.lower}
            label={"특정시간이전 도착시간"}
            handleReceiverTime={handleReceiverTime(
              "completeRequestTime",
              "lower"
            )}
          />
          <InputTimeForm
            time={receiverInfo.completeRequestTime.upper}
            label={"특정시간이후 도착시간"}
            handleReceiverTime={handleReceiverTime(
              "completeRequestTime",
              "upper"
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog(false)} color="primary">
            취소
          </Button>
          <Button onClick={changeAddress} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReceiverDialog;
