import {
  idCheck,
  corpIdCheck,
  passwordCheck as passwordTest,
  emailIdCheck as emailIdTest,
  regPhone1,
  regPhone2,
  regPhone3,
} from "./core";

export const checkField = (value) =>
  typeof value === "undefined" || value === null ? " " : value;

const isOnlyNumber = /[^0-9]/g;

export const reviewField = (value) =>
  typeof value === "undefined" || value === null || value === "" ? " " : value;

// 법인관리
export const validateCorp = (data) => {
  let errorMessage = "";
  if (data.name === null || data.name.trim() === "") {
    errorMessage = "법인명은 필수 입니다.";
  } else if (data.phone.length > 11) {
    errorMessage = "지역번호 및 휴대폰 번호는 하이픈(-)없이 입력해주세요.";
  } else if (data.registration_number.toString().match(isOnlyNumber) !== null) {
    errorMessage = "사업자번호는 공백 없이 숫자만 입력 가능합니다.";
  } else if (data.fixed_date <= 0 || data.fixed_date > 31) {
    errorMessage = "기준일을 확인해주세요 1 ~ 31";
  } else if (data.discount_rate > 100) {
    errorMessage = "할인율은 100보다 클 수 없습니다.";
  } else if (data.corporate_credit < 0) {
    errorMessage = "크레딧은 0보다 작을 수 없습니다.";
  } else if (!data.contracted) {
    errorMessage = "계약일을 선택하여 주세요.";
  }

  return errorMessage;
};

/**
 * @description 부서관리
 * @param { number } legacyCredit 저장된 기존 부서 크레딧
 * @param { object } corp 재호출된 잔여 법인 크레딧
 */
export const validateDepart = (data, legacyCredit, corp) => {
  if (data.name == null || data.name.trim() === "")
    return "부서명은 필수입니다.";
  else if (
    isNaN(parseInt(data.limit_budget)) &&
    data.limit_budget.trim() === ""
  )
    return "예산한도를 확인해주세요";
  // 부서 크레딧 변경시
  else if (legacyCredit !== Number(data.department_credit)) {
    const increaseRange = data.department_credit - legacyCredit;
    if (increaseRange > corp.corporate_credit)
      return "부서 크레딧 증가분은 법인 크레딧 한도를 넘을 수 없습니다.";
    else return "";
  }
  // 부서 크레딧 변경 없을시
  else return "";
};

/**
 * @description 유저관리(회원가입)
 * @param { object } user
 * @param { boolean } admin
 * @returns { string }
 */
export const validateAddUser = (
  { username, password, passwordCheck, fullname, phone, email, corporation },
  admin
) => {
  let errorMessage = "";
  if (username.trim() === "") {
    errorMessage = "아이디는 필수 입니다.";
  } else if (!corporation && !idCheck.test(username)) {
    errorMessage =
      "아이디는 5~20자의 영문 소문자, 숫자, 특수기호(.),(_),(-)만 사용 가능합니다."; // 일반 회원가입
  } else if (corporation && !corpIdCheck.test(username)) {
    errorMessage =
      "법인회원 아이디는 5~30자의 영문 소문자, 숫자, 특수기호(.),(_),(-)(@)만 사용 가능합니다."; // 법인 회원가입
  } else if (password.trim() === "") {
    errorMessage = "비밀번호는 필수 입니다.";
  } else if (!passwordTest.test(password)) {
    errorMessage = "비밀번호는 8~16자의 영문자, 숫자, 특수문자 조합입니다.";
  } else if (password !== passwordCheck) {
    errorMessage = "비밀번호가 일치하지 않습니다.";
  } else if (fullname.trim() === "") {
    errorMessage = "이름은 필수 입니다.";
  } else if (phone.trim() === "") {
    errorMessage = "연락처는 필수 입니다.";
  } else if (
    !(regPhone1.test(phone) || regPhone2.test(phone) || regPhone3.test(phone))
  ) {
    errorMessage = "잘못된 연락처입니다.";
  } else if (email.trim() !== "" && !emailIdTest.test(email)) {
    errorMessage = "사용할 수 없는 EMAIL 입니다.";
  } else if (admin && !corporation) {
    errorMessage = "권한등록시 법인을 선택해주세요.";
  }

  return errorMessage;
};

/**
 * @description 유저관리(회원수정)
 * @param { object } user
 * @param { boolean } admin
 * @returns { string }
 */
export const validateModifyUser = (
  { isd_c_code, fullname, email, user_credit, corporation },
  admin
) => {
  let errorMessage = "";
  if (isd_c_code.trim() === "") {
    errorMessage = "고객코드는 필수 입니다.";
  } else if (fullname.trim() === "") {
    errorMessage = "이름은 필수 입니다.";
  } else if (email && email.trim() !== "" && !emailIdTest.test(email)) {
    errorMessage = "잘못된 이메일입니다.";
  } else if (user_credit < 0) {
    errorMessage = "크레딧은 0보다 작을 수 없습니다.";
  } else if (admin && !corporation) {
    errorMessage = "권한등록시 법인을 선택해주세요.";
  }

  return errorMessage;
};

// 24시 콜화물
export const validateCall24 = (call24) => {
  let errorMessage = "";
  if (!call24.sender || call24.sender.trim() === "") {
    errorMessage = "출발지 주소를 확인해주세요!";
  } else if (!call24.receiver || call24.receiver.trim() === "") {
    errorMessage = "도착지 주소를 확인해주세요!";
  } else if (isNaN(call24.charge) || call24.charge < 20000) {
    errorMessage = "20,000원 미만으로는 배차가 불가합니다!";
  } else if (call24.vehicle_type === "MOTORCYCLE") {
    errorMessage = "차량 종류를 확인해주세요!";
  }

  return errorMessage;
};

export const validateOrder = (data) => {
  let errorMessage = "";
  if (data.isd_order_number.toString().match(isOnlyNumber) !== null) {
    errorMessage = "인성 주문 번호는 공백 없이 숫자만 입력 가능합니다.";
  } else if (
    data.rider_phone_number.toString().match(isOnlyNumber) !== null ||
    data.rider_phone_number.toString().length > 12
  ) {
    errorMessage = "기사 연락처는 공백 없이 11자리 숫자만 입력 가능합니다.";
  } else if (data.location_charge.toString().match(isOnlyNumber) !== null) {
    errorMessage = "배송요금은 숫자만 입력 가능합니다.";
  } else if (data.extra_charge.toString().match(isOnlyNumber) !== null) {
    errorMessage = "추가요금은 숫자만 입력 가능합니다.";
  } else if (data.delivery_charge.toString().match(isOnlyNumber) !== null) {
    errorMessage = "배송실비는 숫자만 입력 가능합니다.";
  }

  return errorMessage;
};

export const validateSendBill = (sender) => {
  let errorMessage = "";
  if (
    sender.emailAddress?.trim() !== "" &&
    !emailIdTest.test(sender.emailAddress)
  ) {
    errorMessage = "올바른 이메일 주소를 입력하세요!";
  } else if (!sender.emailTitle) {
    errorMessage = "이메일 제목을 입력해주세요!";
  } else if (!sender.emailText) {
    errorMessage = "이메일 본문을 입력해주세요!";
  }

  return errorMessage;
};
