type ChunkOption<T> = {
  chunkSize: number;
  array: T[];
};

/**
 * 배열을 청크 단위로 나누어 반환합니다.
 * @param {ChunkOption<T>} chunkOption - 배열을 청크 단위로 나누기 위한 옵션
 * @param {number} chunkOption.chunkSize - 각 청크의 크기
 * @param {T[]} chunkOption.array - 청크로 나눌 배열
 * @returns {T[][]} 청크로 나눈 배열
 */
export const chunk = <T>({ chunkSize, array }: ChunkOption<T>): T[][] => {
  const ret: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    ret.push(array.slice(i, i + chunkSize));
  }

  return ret;
};
