export type UserState = {
  username: string;
  password: string;
  fullname: string;
  department: string;
  phone: string;
  email: string;
};

export type UserType = {
  id: string;
  isd_c_code: string;
  username: string;
  fullname: string;
  password: string;
  passwordCheck: string;
  phone: string;
  email: string | null;
  platform: string[];
  user_credit: number;
  corporation: string;
  corporation_name: string;
  corporation_channel: string;
  channel: string;
  department: string | number;
};

export type UserByPkType = {
  corporations_corporation: {
    id: string;
    name: string;
    channel: string;
  };
  corporations_department: {
    id: number;
    name: string;
    administrator_id: string;
  };
  email: string | null;
  employee_number: string | null;
  fullname: string;
  id: string;
  isd_c_code: string;
  phone: string;
  platform: string[];
  user_credit: number;
  username: string;
  channel: string;
};

export type CorpListType = {
  corporations_departments: DepartListType[];
  id: string;
  name: string;
  channel?: string;
};

export type DepartListType = {
  id: number;
  name: string;
};

export type UserCorporationType = {
  letter: string;
  id: string;
  name: string;
  channel: string;
  corporations_departments: {
    id: number;
    name: string;
  }[];
};

export const DEFAULT_CORP = [
  {
    corporations_departments: [
      {
        id: 0,
        name: "",
      },
    ],
    id: "",
    name: "",
    channel: "",
  },
];

export const DEFAULT_DEPART = [
  {
    id: 0,
    name: "",
  },
];

export const DEFAULT_USER = {
  id: "",
  isd_c_code: "",
  username: "",
  fullname: "",
  password: "",
  passwordCheck: "",
  phone: "",
  email: "",
  platform: [],
  user_credit: 0,
  corporation: "",
  corporation_name: "",
  corporation_channel: "",
  channel: "algoquick",
  department: " ",
};

export const PLATFORMS = [
  "ALGOQUICK",
  "BIZPLAY",
  "CAKE",
  "DAOUOFFICE",
  "INNOCEAN",
  "YEDAHAM",
];

export interface UserDetailProps {
  row: UserType;
  corp: CorpListType[];
  depart: DepartListType[];
  onChange: (e: any) => void;
  onSubmit: (e: React.FormEvent) => void;
  handleUserAdmin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePlatform: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export type DefaultValue = {
  id: string;
  name: string;
};
