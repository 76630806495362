import styled from "styled-components";
import { ClusterResponse, SenderType } from "./types";
import { toISODateString } from "../../lib/datetime";
import * as XLSX from "xlsx";
import ExcelFileDownload from "../../lib/excel/ExcelFileDownload";
import {
  CLUSTER_MAINTABLE_COLUMNS,
  CLUSTER_SUBTABLE_COLUMNS,
} from "./tableColumns";
import { getClusterMainTableRows, getSubtableRowsByClusterId } from "./utils";
import {
  getClusterEstimatedArrivalTimes,
  getClusterGeometries,
  getClusterPointAddresses,
} from "./utils";
import { Fragment } from "react";
import { ColInfo } from "xlsx";
interface ClusterTableProps {
  clusters: ClusterResponse;
  senderInfo: SenderType;
  expandedClusterId: string[];
  onToggleCluster: (clusterId: string) => void;
}

function ClusterTable({
  clusters,
  senderInfo,
  expandedClusterId,
  onToggleCluster,
}: ClusterTableProps) {
  const clusterEntries = Object.entries(clusters);
  const mainTableBodyRowsByClusterId = getClusterMainTableRows(clusterEntries);
  const geometriesByClusterId = getClusterGeometries(clusterEntries);
  const pointAddressesByClusterId = getClusterPointAddresses(
    clusterEntries,
    senderInfo
  );
  const estimatedArrivalTimesByClusterId =
    getClusterEstimatedArrivalTimes(clusterEntries);

  const subTableRowsByClusterId = getSubtableRowsByClusterId({
    clusterEntries,
    clusterPointAddresses: pointAddressesByClusterId,
    clusterGeometries: geometriesByClusterId,
    clusterEstimatedArrivalTimes: estimatedArrivalTimesByClusterId,
    senderInfo,
  });

  const excelDownload = () => {
    type SubtableExcelRow = [
      클러스터: string,
      배송순서: string,
      고객명: string,
      주소: string,
      박스: string,
      이동거리: string,
      위도: string,
      경도: string,
      특정시간이후도착: string,
      특정시간이전도착: string,
      도착예정시간: string,
      타임윈도우충족여부: string,
    ];

    const pickupRequestTime = senderInfo.pickupRequestTime;

    const fileName = `${
      senderInfo.clientName
    }_클러스터링_결과(${toISODateString(pickupRequestTime)}).xlsx`;

    const excelData: SubtableExcelRow[] = [];
    const maxLengthForEachColumn: number[] = [];

    const headerRow: SubtableExcelRow = [
      "클러스터",
      "배송순서",
      "고객명",
      "주소",
      "박스",
      "이동 거리",
      "위도",
      "경도",
      "특정시간 이후도착",
      "특정시간 이전도착",
      "구간 별 도착 예정시간",
      "타임윈도우 충족 여부",
    ];

    // 헤더 추가
    excelData.push(headerRow);
    headerRow.forEach((cell, index) => {
      const cellMaxLength = maxLengthForEachColumn[index] ?? 0;
      const currentCellLength = cell.length;
      if (cellMaxLength < currentCellLength) {
        maxLengthForEachColumn[index] = currentCellLength;
      }
    });

    Object.values(subTableRowsByClusterId).forEach((rows) => {
      rows.forEach((row) => {
        const excelRow: SubtableExcelRow = [
          row.clusterKey,
          row.pointType,
          row.clientName,
          row.address,
          row.box,
          row.distance,
          row.latitude,
          row.longitude,
          row.completeRequestTimeLower,
          row.completeRequestTimeUpper,
          row.arrivalTime,
          row.withinTimeWindow,
        ];
        excelData.push(excelRow);
        excelRow.forEach((cell, index) => {
          const cellMaxLength = maxLengthForEachColumn[index] ?? 0;
          const currentCellLength = cell.length;
          if (cellMaxLength < currentCellLength) {
            maxLengthForEachColumn[index] = currentCellLength;
          }
        });
      });
      excelData.push(["", "", "", "", "", "", "", "", "", "", "", ""]);
    });

    const cols: ColInfo[] = maxLengthForEachColumn.map((length) => ({
      wch: length + 10,
    }));
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    worksheet["!cols"] = cols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "클러스터링 결과");

    XLSX.writeFile(workbook, fileName);
  };

  return (
    <>
      <ExcelDownloadSection>
        <ExcelFileDownload
          isIcon={true}
          color="primary"
          onClick={excelDownload}
        />
      </ExcelDownloadSection>
      <Table>
        <thead>
          <tr>
            {CLUSTER_MAINTABLE_COLUMNS.map((column) => (
              <Th key={column.key}>{column.label}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(mainTableBodyRowsByClusterId).map(
            ([clusterId, cluster], index) => {
              const isExpanded = expandedClusterId.includes(clusterId);
              return (
                <Fragment key={clusterId}>
                  <tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      <ToggleButton onClick={() => onToggleCluster(clusterId)}>
                        {cluster.cluster} {isExpanded ? "▲" : "▼"}
                      </ToggleButton>
                    </Td>
                    <Td>{cluster.totalCount}</Td>
                    <Td>{cluster.totalBox}개</Td>
                    <Td>{cluster.totalTime}</Td>
                    <Td>{cluster.totalDistance}</Td>
                  </tr>
                  {isExpanded && (
                    <tr>
                      <Td colSpan={7}>
                        <SubTable>
                          <thead>
                            <tr>
                              {CLUSTER_SUBTABLE_COLUMNS.slice(1).map(
                                (column) => (
                                  <Th key={column.key}>{column.label}</Th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {subTableRowsByClusterId[clusterId].map((row) => {
                              return (
                                <tr key={row.pointType}>
                                  <Td>{row.pointType}</Td>
                                  <Td>{row.clientName}</Td>
                                  <Td>{row.address}</Td>
                                  <Td>{row.box}</Td>
                                  <Td>{row.distance}</Td>
                                  <Td>{row.latitude}</Td>
                                  <Td>{row.longitude}</Td>
                                  <Td>{row.completeRequestTimeLower}</Td>
                                  <Td>{row.completeRequestTimeUpper}</Td>
                                  <Td>{row.arrivalTime}</Td>
                                  <Td>{row.withinTimeWindow}</Td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </SubTable>
                      </Td>
                    </tr>
                  )}
                </Fragment>
              );
            }
          )}
        </tbody>
      </Table>
    </>
  );
}

export default ClusterTable;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Th = styled.th`
  padding: 12px;
  border: 1px solid #e0e0e0;
  text-align: left;
  background-color: #f5f5f5;
`;

export const Td = styled.td`
  padding: 12px;
  border: 1px solid #e0e0e0;
  text-align: left;
  font-size: 14px;
`;

export const SubTable = styled.table`
  width: 100%;
  margin: 10px 0;
  background-color: #f9f9f9;

  ${Th}, ${Td} {
    padding: 8px;
    border: 1px solid #e0e0e0;
  }
`;

const ExcelDownloadSection = styled.section`
  display: flex;
  justify-content: flex-end;
  padding: 12px;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;
