export const headRows = [
  { id: "created", numeric: false, disablePadding: false, label: "등록시간" },
  { id: "id", numeric: false, disablePadding: false, label: "주문번호" },
  {
    id: "sender_jibun_address_sort",
    numeric: false,
    disablePadding: false,
    label: "출발지 주소",
  },
  {
    id: "sender_name",
    numeric: false,
    disablePadding: false,
    label: "출발지 이름",
  },
  {
    id: "receiver_jibun_address_sort",
    numeric: false,
    disablePadding: false,
    label: "도착지 주소",
  },
  {
    id: "receiver_name",
    numeric: false,
    disablePadding: false,
    label: "도착지 이름",
  },
  {
    id: "rider_name",
    numeric: false,
    disablePadding: false,
    label: "기사 이름",
  },
  {
    id: "rider_phone_number",
    numeric: false,
    disablePadding: false,
    label: "기사 연락처",
  },
  {
    id: "rider_score",
    numeric: false,
    disablePadding: false,
    label: "기사 평점",
  },
  {
    id: "checkerFullname",
    numeric: false,
    disablePadding: false,
    label: "확인 관리자",
  },
] as const;

export const ScoreColor: string[] = [
  "#ffffff",
  "#ffd8d8",
  "#ffd8d8",
  "#c9e6e9",
  "#c3ffff",
  "#c3ffff",
];

export type ListReviewType = {
  created: string;
  id: string;
  sender_address: string;
  sender_name: string;
  receiver_address: string;
  receiver_name: string;
  rider_name: string;
  rider_phone_number: string;
  is_broken: boolean;
  is_delayed: boolean;
  is_unkind: boolean;
  review: string;
  rider_score: number;
  fullname: null | string;
  checkerFullname: string;
};

export type ReviewDataType = {
  created: string;
  id: string;
  is_unkind: boolean;
  is_broken: boolean;
  is_delayed: boolean;
  review: string;
  rider_id: string;
  rider_score: number;
  orders_rider: {
    id: string;
    rider_name: string;
    rider_phone_number: string;
  };
  users_user: null | {
    fullname: string;
  };
};

export type ReviewInfoType = {
  id: string;
  client_name: string;
  client_phone_number: string;
  sender_name: string;
  sender_staff: string;
  sender_phone_number: string;
  sender_address: string;
  sender_jibun_address: string;
  sender_address_detail: string;
  receiver_name: string;
  receiver_staff: string;
  receiver_phone_number: string;
  receiver_address: string;
  receiver_jibun_address: string;
  receiver_address_detail: string;
};
