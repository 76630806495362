import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { AxiosResponse } from "axios";
import { TextField, Button } from "@material-ui/core";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import ko from "date-fns/locale/ko";
import * as XLSX from "xlsx";

import ExcelFileDownload from "../../lib/excel/ExcelFileDownload";
import ExcelFileUpload from "../../lib/excel/ExcelFileUpload";
import {
  getLatLngData,
  getObpCluster,
  getClusterResult,
} from "../../lib/api/orders";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { SenderType } from "./types";
import {
  DownloadChargeRequestData,
  GetLatLngResponseData,
  GetObpClusterRequestData,
  GetObpClusterResponseData,
} from "../../lib/api/orders.type";
import {
  ClusterInternalData,
  ClusterInternalDataWithCharge,
  ClusterInternalDataWithChargeAndCluster,
  formatUpperLowerTime,
  UploadedClusterExcelColumn,
} from "./utils";
import { GroupWrapper } from "../../pages/GroupOrder/style";
import { RowDivider } from "../../components/common/Base";

interface ClusterUploadContainerProps {
  setClustering: React.Dispatch<React.SetStateAction<any>>;
  setSenderInfo: React.Dispatch<React.SetStateAction<SenderType>>;
  senderInfo: SenderType;
}

function ClusterUploadContainer({
  setClustering,
  setSenderInfo,
  senderInfo,
}: ClusterUploadContainerProps) {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");

  const [mergedData, setMergedData] = useState<
    ClusterInternalDataWithChargeAndCluster[]
  >([]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    switch (name) {
      case "senderClientName":
        return setSenderInfo((prev) => ({ ...prev, clientName: value }));
      case "senderAddress":
        return setSenderInfo((prev) => ({ ...prev, address: value }));
      case "maxCluster":
        return setSenderInfo((prev) => ({
          ...prev,
          maxCluster: Number(value),
        }));
      default:
        break;
    }
  };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setSenderInfo((prev) => ({ ...prev, pickupRequestTime: date }));
    }
  };

  const sampleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href =
      "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/cluster_bundle_250220.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFile = useCallback(
    (file: File) => {
      dispatch(startGlobalLoading());
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const bstr = e.target && e.target.result;
        const wb = XLSX.read(bstr, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json<UploadedClusterExcelColumn>(ws, {
          // header: 1,
          defval: "",
          blankrows: false,
        });

        const internalClusterData = data.map<ClusterInternalData>((row) => {
          const lower = formatUpperLowerTime(row["특정시간 이후도착"]);
          const upper = formatUpperLowerTime(row["특정시간 이전도착"]);

          return {
            client_name: row.고객명,
            box: row["박스 수량"],

            lower,
            upper,

            vehicleType: "MOTORCYCLE",
            vehicleOption: "",

            sender: senderInfo.address,
            receiver: row["도착지 주소"],
          };
        });

        // 업로드 엑셀파일에 위경도,거리 데이터 추가
        const lagLagResponse: AxiosResponse<
          GetLatLngResponseData,
          DownloadChargeRequestData
        > = await getLatLngData(internalClusterData);
        const lagLagData = lagLagResponse.data;

        const mergedClusterData =
          internalClusterData.map<ClusterInternalDataWithCharge>((item) => {
            const match = lagLagData.find(
              (charge) => charge.originalReceiver === item.receiver
            );

            if (match) {
              return {
                ...item,
                ...match,
              };
            }
            alert("매칭되는 데이터가 없습니다.");
            throw new Error("매칭되는 데이터가 없습니다.");
          });

        // obp bodyData 추출
        const date = format(senderInfo.pickupRequestTime, "yyyy-MM-dd");
        const time = format(senderInfo.pickupRequestTime, "HH:mm");
        const obpPayload: GetObpClusterRequestData = {
          max_n_clusters: senderInfo.maxCluster,
          sheets: {
            [date]: mergedClusterData.map((data) => {
              return {
                time,
                latitude: data.receiverLatitude,
                longitude: data.receiverLongitude,
              };
            }),
          },
        };
        const [{ data: obpResponse }]: GetObpClusterResponseData =
          await getObpCluster(obpPayload);

        const finalClusterData =
          mergedClusterData.map<ClusterInternalDataWithChargeAndCluster>(
            (item, index) => {
              const match = obpResponse.find((item) => item.no === index);
              if (match) {
                return {
                  ...item,
                  cluster: match.cluster,
                };
              }
              alert("obp 데이터와 매칭되는 클러스터가 없습니다.");
              throw new Error("obp 데이터와 매칭되는 클러스터가 없습니다.");
            }
          );

        setMergedData(finalClusterData);
        dispatch(finishGlobalLoading());
      };
      reader.readAsArrayBuffer(file);
    },
    [senderInfo]
  );
  const handleExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!senderInfo.address || !senderInfo.maxCluster)
        return alert("출발지 주소 및 클러스터수를 입력해주세요.");

      const files = e.target.files;
      if (files && files[0]) {
        handleFile(files[0]);
        setFileName(files[0].name);
      }
    },
    [handleFile, setFileName]
  );

  const moveResultPage = async () => {
    if (mergedData.length < 1)
      return alert("변환된 데이터가 없습니다. 다시 시도해주세요.");

    // cluster/result API의 sender
    const { clientName, pickupRequestTime } = senderInfo;
    const { senderLatitude: latitude, senderLongitude: longitude } =
      mergedData[0];

    // cluster/result의 data
    const bodyTableData = mergedData.map((item) => {
      return {
        client_name: item.client_name,
        address: item.receiver,
        box: item.box,
        latitude: item.receiverLatitude,
        longitude: item.receiverLongitude,
        distance: item.distance,
        cluster: item.cluster,
        ...(item.lower &&
          item.upper && {
            complete_request_time: {
              lower: item.lower,
              upper: item.upper,
            },
          }),
      };
    });

    try {
      dispatch(startGlobalLoading());
      // This is a hole of unknown type.
      const tableResult = await getClusterResult({
        sender: {
          ...(clientName && { client_name: clientName }),
          pickup_request_time: format(pickupRequestTime, "yyyyMMddHHmm"),
          latitude,
          longitude,
        },
        data: bodyTableData,
      });
      setClustering(tableResult.data);
      setSenderInfo((prev) => ({
        ...prev,
        clientName,
        latitude,
        longitude,
        pickupRequestTime,
      }));
    } catch (err: any) {
      const errMsg =
        err?.response?.data.detail || "클러스터링 결과를 확인해주세요.";
      alert(errMsg);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  return (
    <GroupWrapper>
      <h1>클러스터 묶음배송</h1>
      <SectionWrapper>
        <SearchForm>
          <div className="sender-title">출발정보 입력</div>
          <TextField
            label="출발지 명"
            type="text"
            id="senderClientName"
            name="senderClientName"
            value={senderInfo.clientName}
            onChange={onChange}
            variant="outlined"
            fullWidth
          />
          <RowDivider size="0.1" />
          <TextField
            label="출발지 주소"
            type="text"
            id="senderAddress"
            name="senderAddress"
            value={senderInfo.address}
            onChange={onChange}
            variant="outlined"
            fullWidth
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <MobileDateTimePicker
              label="출발 예약 시간"
              format="yyyy-MM-dd HH:mm"
              value={senderInfo.pickupRequestTime}
              slotProps={{
                actionBar: { actions: [] },
                // day: { disableMargin: true },
                textField: { variant: "standard", margin: "normal" },
                toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
              }}
              closeOnSelect
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </SearchForm>
        <SearchForm>
          <div className="sender-title">클러스터수 입력</div>
          <TextField
            label="최대 클러스터"
            type="number"
            id="maxCluster"
            name="maxCluster"
            value={senderInfo.maxCluster}
            onChange={onChange}
            inputProps={{ min: 0, step: 1 }}
            variant="outlined"
            fullWidth
          />
        </SearchForm>
        <ExcelFileDownload isIcon={true} size={1} onClick={sampleDownload} />
        <ExcelFileUpload
          isIcon={true}
          fileName={fileName}
          size={1}
          disabled={false}
          onChange={handleExcel}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ padding: "10px 0", fontSize: "15px" }}
          onClick={moveResultPage}
          disabled={mergedData.length < 1}
        >
          클러스터 데이터 변환
        </Button>
      </SectionWrapper>
    </GroupWrapper>
  );
}

export default ClusterUploadContainer;

const SearchForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 1rem;
  width: 100%;
  .sender-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 3rem auto 0 auto;
  width: 30%;
`;
