type SortableData = {
  [key: string]: any;
};

const desc = <T extends SortableData>(a: T, b: T, orderBy: keyof T) => {
  const aValue = a[orderBy] ?? "";
  const bValue = b[orderBy] ?? "";

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

export const stableSort = <T extends SortableData>(
  array: T[],
  cmp: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getSorting = <T extends SortableData>(
  order: string,
  orderBy: keyof T | ""
) => {
  return order === "desc"
    ? (a: T, b: T) => desc(a, b, orderBy)
    : (a: T, b: T) => -desc(a, b, orderBy);
};
