import { Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import { SheetJSFT } from "../../pages/GroupOrder/utils";

interface ExcelUploadProps {
  isIcon: boolean;
  buttonName?: string;
  fileName?: string;
  color?: "default" | "primary" | "secondary";
  size?: number;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// 사용하는 상위 컴포넌트에서 "upload-button"으로 스타일링
function ExcelFileUpload({
  isIcon,
  buttonName,
  fileName,
  color = "default",
  size = 0,
  disabled = false,
  onChange,
}: ExcelUploadProps) {
  return (
    <>
      <Button
        className="upload-button"
        variant="outlined"
        color={color}
        size="large"
        component="label"
        htmlFor="excel-upload"
        style={{ marginBottom: `${size}rem` }}
        startIcon={isIcon ? <CloudUpload /> : null}
        disabled={disabled}
      >
        <input
          type="file"
          className="form-control"
          id="excel-upload"
          accept={SheetJSFT}
          style={{ display: "none" }}
          onChange={onChange}
        />
        {buttonName || "엑셀파일 업로드"}
      </Button>
      <p style={{ marginBottom: `${size}rem` }}>
        {fileName && `파일이름 : ${fileName}`}
      </p>
    </>
  );
}

export default ExcelFileUpload;
