import React, { useState, useEffect } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Snackbar } from "@material-ui/core";

import UserDetail from "../../components/user/UserDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { getCorporationsWithDepartments } from "../../lib/hasura/corporations";
import { destructResponse } from "../../lib/hasura/common";
import { validateModifyUser } from "../../lib/validate";
import {
  DEFAULT_CORP,
  DEFAULT_DEPART,
  DEFAULT_USER,
  UserByPkType,
  UserType,
  CorpListType,
  DepartListType,
  UserCorporationType,
} from "./types";
import {
  getUser,
  resetAdminAuthority,
  updateUser,
} from "../../lib/hasura/users";
import { getSyncUser, postCreateUser } from "../../lib/api/users";
import { postLog } from "../../lib/hasura/users";
import { LOG_TYPE } from "../../lib/constants/constants";

function UserDetailContainer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = history.location.pathname.replace("/user/", "");
  const [userState, userAuth] = useSelector(
    ({ user, userAuth }: RootStateOrAny) => [user.user, userAuth]
  );

  const [user, setUser] = useState<UserType>(DEFAULT_USER);
  const [orgUserCorporation, setOrgUserCorporation] = useState<string>("");
  const [corp, setCorp] = useState<CorpListType[]>(DEFAULT_CORP);
  const [depart, setDepart] = useState<DepartListType[]>(DEFAULT_DEPART);
  const [legacyChannel, setLegacyChannel] = useState<string>("");
  const [legacyCorporationChannel, setLegacyCorporationChannel] =
    useState<string>("");
  const [admin, setAdmin] = useState(false);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const fetchData = async () => {
    const channel = userState?.channel || "algoquick";
    dispatch(startGlobalLoading());
    try {
      const corpData = await destructResponse<CorpListType[]>(
        "corporations_corporation",
        () => getCorporationsWithDepartments(channel)
      );
      const userData = await destructResponse<UserByPkType>(
        "users_user_by_pk",
        () => getUser(userId)
      );

      setCorp(corpData);
      setUser({
        ...user,
        id: userData.id,
        isd_c_code: userData.isd_c_code || "",
        username: userData.username,
        fullname: userData.fullname,
        phone: userData.phone,
        email: userData.email || "",
        platform: userData.platform,
        user_credit: userData.user_credit || 0,
        corporation: userData.corporations_corporation?.id || "",
        corporation_name: userData.corporations_corporation?.name || "",
        corporation_channel: userData.corporations_corporation?.channel || "",
        channel: userData.channel || "",
        department: userData.corporations_department?.id || " ",
      });
      setOrgUserCorporation(userData.corporations_corporation?.id || "");
      setLegacyCorporationChannel(
        userData.corporations_corporation?.channel || ""
      );
      setLegacyChannel(userData.channel || "");
    } catch {
      const msg = "법인 정보를 불러올 수 없습니다. 개발팀에 문의해주세요.";
      openSnackbar(msg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchData();
  }, [userId]);

  useEffect(() => {
    const { corporations_departments } =
      corp.filter((option) => {
        return option.id === user.corporation;
      })[0] || [];
    if (corporations_departments) setDepart(corporations_departments);
  }, [corp, user]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      case "corporation":
        if (!value) {
          // 법인 삭제
          setUser({
            ...user,
            corporation: "",
            corporation_name: "",
            corporation_channel: "",
            department: " ",
          });
          setDepart([]);
        } else {
          // 법인 변경
          const newValue = value as unknown as UserCorporationType;
          setUser({
            ...user,
            corporation: newValue.id,
            corporation_name: newValue.name,
            corporation_channel: newValue.channel,
            department: " ",
          });
          const { corporations_departments } = corp.filter(
            (option) => option.id === newValue.id
          )[0];
          setDepart(corporations_departments);
        }
        break;
      default:
        setUser({
          ...user,
          [name]: value,
        });
        break;
    }
  };

  const handleUserAdmin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAdmin(checked);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const invalidMessage = validateModifyUser(user, admin);
      const onChangeChannel = user.corporation_channel;

      if (invalidMessage !== "") throw new Error(invalidMessage);
      await updateUser(user);

      // channel 값이 변경되었을 경우
      if (legacyChannel !== user.channel) {
        await getSyncUser(user.phone, user.channel);
        await fetchData();
      }

      // 경기채널 법인 설정시
      if (
        (legacyCorporationChannel === "" &&
          onChangeChannel === "algoquick_g1") ||
        (legacyCorporationChannel === "algoquick_g1" &&
          onChangeChannel === "algoquick") ||
        (legacyCorporationChannel === "algoquick" &&
          onChangeChannel === "algoquick_g1")
      ) {
        await getSyncUser(user.phone, user.corporation_channel);
        await fetchData();
      }

      if (orgUserCorporation !== user.corporation) {
        await resetAdminAuthority(orgUserCorporation, user.id);
        setOrgUserCorporation(user.corporation);
      }

      // adminuserauth 권한 등록
      if (admin) await postCreateUser(user.id);

      await postLog(
        userState.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `회원 수정: ${user.username}(${user.fullname})`,
        "회원 관리"
      );
      const msg = admin
        ? `회원정보 수정 및 권한등록 되었습니다.`
        : "회원정보가 수정되었습니다.";
      openSnackbar(msg, false);
    } catch (err) {
      const msg = (err as Error).message || "회원수정에 실패하였습니다.";
      openSnackbar(msg, true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
    history.goBack();
  };

  const handlePlatform = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;
    let platform: string[];

    if (checked) {
      platform = user.platform.concat(name);
    } else {
      platform = user.platform.filter((el) => el !== name);
    }
    setUser({ ...user, platform });
  };

  return (
    <>
      {user.id && (
        <UserDetail
          row={user}
          corp={corp}
          depart={depart}
          onChange={onChange}
          onSubmit={onSubmit}
          handleUserAdmin={handleUserAdmin}
          handlePlatform={handlePlatform}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default UserDetailContainer;
