export type CorporateDept = {
  modified: string;
  administrator_id: string | null;
  corporations_corporation: { name: string };
  address_book_enable: boolean;
  department_credit: number;
  department_credit_enable: boolean;
  id: number;
  limit_budget: number;
  name: string;
  users_users: {
    fullname: string;
    email: string;
    phone: string;
    username: string;
    corporations_department: {
      name: string;
    };
  }[];
};

export const headRows = [
  {
    id: "corporation_sort",
    numeric: false,
    disablePadding: false,
    label: "법인명",
    width: "15%",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "부서명",
    width: "15%",
  },
  {
    id: "members_sort",
    numeric: false,
    disablePadding: false,
    label: "이용자수",
    width: "9%",
  },
  {
    id: "limit_budget",
    numeric: false,
    disablePadding: false,
    label: "예산한도",
    width: "10%",
  },
  {
    id: "department_credit_enable",
    numeric: false,
    disablePadding: false,
    label: "부서 크레딧 사용여부",
    width: "15%",
  },
  {
    id: "department_credit",
    numeric: false,
    disablePadding: false,
    label: "부서 크레딧",
    width: "14%",
  },
  {
    id: "address_book_enable",
    numeric: false,
    disablePadding: false,
    label: "부서 주소록 사용여부",
    width: "24%",
  },
] as const;

export const departmentUserListheadRows = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "No",
    width: "1%",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "아이디",
    width: "10%",
  },
  {
    id: "department_name",
    numeric: false,
    disablePadding: false,
    label: "부서명",
    width: "15%",
  },
  {
    id: "fullname",
    numeric: false,
    disablePadding: false,
    label: "이름",
    width: "10%",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "연락처",
    width: "10%",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "이메일",
    width: "15%",
  },
] as const;
