import type {
  BreakpointProperty,
  OptimizationProperty,
  PredictionLineStringProperty,
  PredictionProperty,
  PredictionStartProperty,
  PredictionEndProperty,
  StartProperty,
  EndProperty,
  Geometry,
  PointGeometry,
  LineStringBreakpointProperty,
} from "./types";

export const isLineStringBreakpointProperty = (
  properties: OptimizationProperty
): properties is LineStringBreakpointProperty => {
  return (
    isBreakpointProperty(properties) &&
    "box" in properties &&
    "address" in properties
  );
};

export const isPointGeometry = (
  geometry: Geometry
): geometry is PointGeometry => {
  return geometry.type === "Point";
};

export const isStartPointProperty = (
  property: OptimizationProperty
): property is StartProperty => {
  return property.pointType === "S";
};

export const isEndPointProperty = (
  property: OptimizationProperty
): property is EndProperty => {
  return property.pointType === "E";
};

export const isBreakpointProperty = (
  property: OptimizationProperty
): property is BreakpointProperty => {
  return property.pointType.startsWith("B");
};

export const isPredictionStartProperty = (
  property: PredictionProperty
): property is PredictionStartProperty => {
  return "pointType" in property && property.pointType === "S";
};

export const isPredictionEndProperty = (
  property: PredictionProperty
): property is PredictionEndProperty => {
  return "pointType" in property && property.pointType === "E";
};

export const isPredictionLineStringProperty = (
  property: PredictionProperty
): property is PredictionLineStringProperty => {
  return !("pointType" in property);
};
