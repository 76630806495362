import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import { headRows, ScoreColor, ListReviewType } from "./ReviewListType";
import { formatDate } from "../../lib/datetime";
import { addressDong } from "../../lib/address";
import { stableSort, getSorting } from "../dataTable/base";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";

interface reviewsProps {
  rows: ListReviewType[];
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeFirst: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type SortableReviewListRowType = ListReviewType & {
  [K in Exclude<(typeof headRows)[number]["id"], keyof ListReviewType>]:
    | string
    | number
    | boolean;
};

function ReviewList({
  rows,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeFirst,
}: reviewsProps) {
  const history = useHistory();

  /* EnhancedTableHead */
  // direction = {asc || desc}
  const [order, setOrder] = useState("asc");
  // active = {true || false}
  const [orderBy, setOrderBy] = useState<keyof SortableReviewListRowType | "">(
    ""
  );

  const handleRequestSort = (
    e: React.MouseEvent,
    property: keyof SortableReviewListRowType
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    history.push(`/rider-review/${id}`);
  };

  const emptyRows =
    count !== 0
      ? rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
      : 0;

  const sortableReviewListRows: SortableReviewListRowType[] = rows.map(
    (row) => ({
      ...row,
      sender_jibun_address_sort: addressDong(row.sender_address) || "",
      receiver_jibun_address_sort: addressDong(row.receiver_address) || "",
    })
  );

  return (
    <>
      <PaddingTopPaper>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headRows={headRows}
          />
          <TableBody>
            {stableSort(
              sortableReviewListRows,
              getSorting<SortableReviewListRowType>(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((el: ListReviewType) => {
                const {
                  created,
                  id,
                  sender_address,
                  sender_name,
                  receiver_address,
                  receiver_name,
                  rider_name,
                  rider_phone_number,
                  rider_score,
                  checkerFullname = "",
                } = el;

                return (
                  <OrderTableRow
                    hover
                    tabIndex={-1}
                    key={id}
                    onClick={() => handleRowClick(id)}
                    color={checkerFullname ? "#fff" : ScoreColor[rider_score]}
                  >
                    <TableCell>{formatDate(created)}</TableCell>
                    <TableCell>{id}</TableCell>
                    <TableCell>{addressDong(sender_address)}</TableCell>
                    <TableCell>{sender_name}</TableCell>
                    <TableCell>{addressDong(receiver_address)}</TableCell>
                    <TableCell>{receiver_name}</TableCell>
                    <TableCell>{rider_name}</TableCell>
                    <TableCell>{rider_phone_number}</TableCell>
                    <TableCell>{rider_score}</TableCell>
                    <TableCell>{checkerFullname}</TableCell>
                  </OrderTableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeFirst}
          page={page}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
        />
      </PaddingTopPaper>
    </>
  );
}

export default ReviewList;

const PaddingTopPaper = styled.div`
  margin-top: 1rem;
  border-top: 2px rgba(0, 0, 0, 0.08) solid;
`;

const OrderTableRow = styled(TableRow)`
  background: ${(props) => props.color || "#ffffff"};
`;
