export type InquiryListRowType = {
  created: string;
  id: string;
  name: string;
  monthly_usage_amount: string;
  api_enable: boolean;
  customer_phone: string;
  customer_email: string;
  checkerFullname: string;
  users_user: null | {
    fullname: string;
  };
  payment: string | null;
  status: string | null;
  modified: string | null;
};

export type InquiryType = {
  address: string | null;
  api_enable: boolean;
  check_memo: string | null;
  consignment: string | null;
  created: string;
  customer_email: string;
  customer_name: string | null;
  customer_phone: string;
  id: number;
  inquiry: string | null;
  monthly_usage_amount: string;
  name: string;
  users_user: null | {
    fullname: string;
  };
  payment: string | null;
  status: string | null;
};

export const DEFAULT_INQUIRY = {
  address: "",
  api_enable: false,
  check_memo: "",
  consignment: "",
  created: "",
  customer_email: "",
  customer_name: "",
  customer_phone: "",
  id: 0,
  inquiry: "",
  monthly_usage_amount: "",
  name: "",
  users_user: null,
  payment: null,
  status: null,
};

export const headRows = [
  { id: "created", numeric: false, disablePadding: false, label: "등록시간" },
  { id: "name", numeric: false, disablePadding: false, label: "회사명" },
  {
    id: "monthly_usage_amount",
    numeric: false,
    disablePadding: false,
    label: "월간 사용금액",
  },
  {
    id: "customer_phone",
    numeric: false,
    disablePadding: false,
    label: "연락처",
  },
  {
    id: "customer_email",
    numeric: false,
    disablePadding: false,
    label: "이메일주소",
  },
  {
    id: "api_enable",
    numeric: false,
    disablePadding: false,
    label: "연동 여부",
  },
  {
    id: "checkerFullname",
    numeric: false,
    disablePadding: false,
    label: "확인 관리자",
  },
  {
    id: "payment_sort",
    numeric: false,
    disablePadding: false,
    label: "거래 방법",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "진행 현황",
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "수정일자",
  },
] as const;
