import styled from "styled-components";
import { Button, TextField } from "@material-ui/core";
import {
  FlexBox,
  FlexColumnCenter,
  RowDivider,
  ColumnDivider,
} from "../common/Base";
import Detail from "../common/Detail";
import { formatDate } from "../../lib/datetime";
import { InquiryType } from "../../containers/corporationInquiry/types";
import { HalfTextField } from "../common/StyledTextField";
import { inquiryPayment, inquiryStatus } from "./InquiryDetailStatus";
import { MenuItem } from "@mui/material";

interface InquiryDetailProps {
  inquiryData: InquiryType;
  handleCheckInquiry: () => void;
  handleInquiryOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function InquiryDetail({
  inquiryData: {
    address,
    api_enable,
    check_memo,
    consignment,
    created,
    customer_email,
    customer_name,
    customer_phone,
    inquiry,
    monthly_usage_amount,
    name,
    users_user: user,
    payment,
    status,
  },
  handleCheckInquiry,
  handleInquiryOnChange,
}: InquiryDetailProps) {
  return (
    <FlexColumnCenterInquiry>
      <Detail>
        <FlexBox>
          <TextField
            label="등록날짜"
            id="created"
            name="created"
            value={formatDate(created) || " "}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="확인 관리자"
            id="fullname"
            name="fullname"
            value={user?.fullname || ""}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
          />
        </FlexBox>
      </Detail>
      <RowDivider size="2" />
      <Detail>
        <FlexBox>
          <TextField
            label="회사명"
            id="name"
            name="name"
            value={name || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="회사주소"
            id="address"
            name="address"
            value={address || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="퀵서비스 월간 사용금액"
            id="monthly_usage_amount"
            name="monthly_usage_amount"
            value={monthly_usage_amount || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="알고퀵 API 연동 희망 여부"
            id="api_enable"
            name="api_enable"
            value={api_enable ? "O" : "X"}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="주요 배송품목"
            id="consignment"
            name="consignment"
            value={consignment || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="담당자 이름"
            id="customer_name"
            name="customer_name"
            value={customer_name || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="담당자 연락처"
            id="customer_phone"
            name="customer_phone"
            value={customer_phone || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <ColumnDivider size="3" />
          <TextField
            label="담당자 이메일주소"
            id="customer_email"
            name="customer_email"
            value={customer_email || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="문의사항"
            id="inquiry"
            name="inquiry"
            multiline
            minRows={3}
            value={inquiry || ""}
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="메모 기록"
            id="check_memo"
            name="check_memo"
            multiline
            minRows={3}
            value={check_memo || ""}
            variant="filled"
            margin="normal"
            fullWidth
            onChange={handleInquiryOnChange}
          />
        </FlexBox>
        <RowDivider />
        <Footer>
          <DropDownContainer>
            <HalfTextField
              select
              id="payment"
              name="payment"
              label="거래방법"
              value={payment || ""}
              onChange={handleInquiryOnChange}
              variant="filled"
            >
              {inquiryPayment.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </HalfTextField>
            <HalfTextField
              select
              id="status"
              name="status"
              label="진행현황"
              value={status || ""}
              onChange={handleInquiryOnChange}
              variant="filled"
            >
              {inquiryStatus.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    color: (() => {
                      switch (option.value) {
                        case "WAITING":
                          return "#000000";
                        case "REQUEST":
                          return "#FF69B4";
                        case "DISCUSSING":
                          return "#32CD32";
                        case "SUCCESS":
                          return "#4169E1";
                        case "FAIL":
                          return "#FF0000";
                        default:
                          return "inherit";
                      }
                    })(),
                    fontWeight: "700",
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </HalfTextField>
          </DropDownContainer>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleCheckInquiry()}
          >
            문의확인 및 메모저장
          </Button>
        </Footer>
      </Detail>
    </FlexColumnCenterInquiry>
  );
}
export default InquiryDetail;

export const FlexColumnCenterInquiry = styled(FlexColumnCenter)`
  margin-top: 2rem;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: end;
  gap: 1rem;
`;

const DropDownContainer = styled.div`
  width: 50%;
  display: flex;
  gap: 1rem;
`;
