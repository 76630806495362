export type CorporationsType = {
  address: string | null;
  channel: string;
  contracted: string | null;
  corporate_account_enable: boolean;
  discount_rate: number;
  id: string;
  name: string;
  phone: string | null;
  registration_number: string | null;
  users_user: { username: string } | null;
};

export const headRows = [
  { id: "name", numeric: false, disablePadding: false, label: "법인명" },
  { id: "channel", numeric: false, disablePadding: false, label: "채널명" },
  {
    id: "phone_sort",
    numeric: false,
    disablePadding: false,
    label: "연락처",
  },
  { id: "username", numeric: false, disablePadding: false, label: "관리자" },
  {
    id: "address_sort",
    numeric: false,
    disablePadding: false,
    label: "주소",
  },
  {
    id: "registration_number_sort",
    numeric: false,
    disablePadding: false,
    label: "사업자등록번호",
  },
  {
    id: "contracted_sort",
    numeric: false,
    disablePadding: false,
    label: "계약일",
  },
  {
    id: "corporate_account_enable",
    numeric: false,
    disablePadding: false,
    label: "법인거래사용여부",
  },
  {
    id: "discount_rate",
    numeric: false,
    disablePadding: false,
    label: "할인율(%)",
  },
] as const;
