import React from "react";
import styled from "styled-components";
import { ModalWrapper } from "../common/modal/Modal";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { departmentUserListheadRows } from "../../containers/departments/types";
import { UsersListType } from "../../containers/users/types";

interface DepartmentUserListModalProps {
  open: boolean;
  onClose: () => void;
  users?: UsersListType[];
  departmentName: string;
}

function DepartmentUserListModal({
  open,
  onClose,
  users = [],
  departmentName,
}: DepartmentUserListModalProps) {
  if (!open) return null;

  return (
    <ModalWrapper>
      <ModalForm>
        <section>
          <Title className="title">
            <span className="title-text">{departmentName}</span> 부서 이용자
            목록 ({users.length})
          </Title>
          <CloseButton>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </CloseButton>

          <TableContainer>
            {users.length > 0 ? (
              <Table stickyHeader>
                <EnhancedTableHead
                  order={"asc"}
                  orderBy={""}
                  onRequestSort={() => {}}
                  headRows={departmentUserListheadRows}
                />
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        {user.corporations_department?.name || "-"}
                      </TableCell>
                      <TableCell>{user.fullname}</TableCell>
                      <TableCell>{user.phone}</TableCell>
                      <TableCell>{user.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <EmptyState>사용자가 없습니다.</EmptyState>
            )}
          </TableContainer>
        </section>
      </ModalForm>
    </ModalWrapper>
  );
}

export default DepartmentUserListModal;

const ModalForm = styled.div`
  position: relative;
  padding: 1.5rem 2rem;
  width: 70%;
  height: auto;
  background-color: #ffffff;
  letter-spacing: 1px;
  border-radius: 20px;
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.15);
`;

const Title = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  margin: 1.5rem 1.5rem 0 0;

  .title-text {
    color: #1b91c6;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 0.5rem;
`;

const TableContainer = styled.div`
  margin-top: 1rem;
  max-height: 400px;
  overflow-y: auto;
`;

const EmptyState = styled.div`
  padding: 2rem;
  text-align: center;
  color: #666;
`;
