import axios, { AxiosRequestConfig } from "axios";

type CreateAPIOption = {
  baseURL: string;
  onRequest?: (
    request: AxiosRequestConfig
  ) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
};

export const createAPI = (opts: CreateAPIOption) => {
  const instance = axios.create({
    baseURL: opts.baseURL,
  });
  if (opts.onRequest) {
    instance.interceptors.request.use(opts.onRequest);
  }
  return instance;
};

type InjectToken = () => string | null;
export const authRequest =
  (injectToken: InjectToken) => (request: AxiosRequestConfig) => {
    const token = injectToken();
    if (token) {
      request.headers = {
        ...request.headers,
        Authorization: `AlgoLab ${token}`,
      };
    }
    return request;
  };

export const injectSessionStorage: InjectToken = () => {
  return sessionStorage.getItem("authorization");
};

export const injectDevMasterToken: InjectToken = () => {
  return process.env.REACT_APP_ALGOLAB_TOKEN ?? null;
};
