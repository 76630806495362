import React, { useState, useEffect, useCallback } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  Button as MuiButton,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Snackbar,
} from "@material-ui/core";

import ko from "date-fns/locale/ko";
import * as XLSX from "xlsx";

import LogList from "../../components/log/LogList";
import useSnackbar from "../../hooks/useSnackbar";
import Notice from "../../components/common/Notice";
import { headRows, LogStatus, LogElementType } from "./types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { destructResponse } from "../../lib/hasura/common";
import { LOG_TYPE } from "../../lib/constants/constants";
import { listLogs, listLogsPast, postLog } from "../../lib/hasura/users";
import { getFormatDate, getSubDate } from "../../lib/datetime";
import Button from "../../components/common/Button";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

interface AdminUserAuthMenu {
  menu_id: string;
}

function LogContainer() {
  const dispatch = useDispatch();
  const [userId, ip, authLogMenu] = useSelector(
    ({ user: { user }, userAuth: { ip, userAuth } }: RootStateOrAny) => {
      const authLogMenu = userAuth?.data.users_adminuserauth.find(
        ({ menu_id: menuId }: AdminUserAuthMenu) => menuId === "LOGS"
      );
      return [user?.id, ip, authLogMenu];
    }
  );
  const excelDownloadable = authLogMenu?.excel || false;

  // pagination
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  // logs
  const [logs, setLogs] = useState<LogElementType[]>([]);
  const [logCount, setLogCount] = useState(0);

  // search
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState("today");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchLog();
  }, []);

  // 금일 로그 검색
  const fetchLog = async (search = "") => {
    dispatch(startGlobalLoading());
    try {
      const result = await destructResponse<LogElementType[]>(
        "users_adminuserlog",
        () => listLogs(search)
      );

      doFetchLogs(result);
    } catch (error) {
      openSnackbar("로그 데이터를 받아올 수 없습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };
  // 과거 로그 검색
  const getFetchPastLogs = async (
    start: string,
    end: string,
    search: string
  ) => {
    dispatch(startGlobalLoading());
    try {
      const result = await destructResponse<LogElementType[]>(
        "users_adminuserlog",
        () => listLogsPast(start, end, search)
      );

      doFetchLogs(result);
    } catch (error) {
      openSnackbar("로그 데이터를 받아올 수 없습니다.", true);
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const doFetchLogs = useCallback((resLogs) => {
    setLogs(
      resLogs.map((resLog: LogElementType, i: number) => ({
        ...resLog,
        seq: i + 1,
        userUsername: resLog.users_user?.username || "",
        userFullname: resLog.users_user?.fullname || "",
      }))
    );
    setLogCount(resLogs.length);
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPage(0); // 페이지 초기화
    if (mode === "today") await fetchLog(search);
    else await getFetchPastLogs(start, end, search);
  };

  /* 금일 로그 검색 | 과거 로그 검색 */
  const handleMode = async (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setPage(0); // 페이지 초기화
    setMode((e.target as HTMLInputElement).value);
    setStart(getSubDate(new Date(), 7));
    setEnd(getSubDate(new Date(), 1));

    if (e.target.value === "today") {
      await fetchLog();
    } else {
      // 과거 로그 검색 클릭시 초기화
      setLogs([]);
      setLogCount(0);
    }
  };

  // DatePicker
  const handleStartChange = (date: Date | null) => {
    if (date) setStart(getFormatDate(date));
  };
  const handleEndChange = (date: Date | null) => {
    if (date) setEnd(getFormatDate(date));
  };

  // Pagination
  const handleChangePage = (event: React.MouseEvent | null, newPage: number) =>
    setPage(newPage);

  const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseFloat(event.target.value)); // 25 50 100 페이지 뷰
    setPage(0); // 페이지 뷰 변할때마다 첫페이지 렌더링
  };

  const downloadExcel = async () => {
    const sign = prompt("다운로드 사유를 5글자 이상 기재해주세요.");
    if (!sign) return;
    if (sign.trim().length < 5) return alert("5글자 이상 기재해주세요.");

    await postLog(
      userId,
      ip,
      LOG_TYPE.EXCEL,
      `로그기록 다운: ${sign}`,
      "로그기록"
    );
    doDownloadExcel();
  };
  const doDownloadExcel = () => {
    const fileName =
      mode === "past"
        ? `${start}-${end} 로그 기록.xlsx`
        : `${getFormatDate()} 로그 기록.xlsx`;

    const rowsToExport = logs.map((log: LogElementType) =>
      headRows.reduce(
        (acc, { id, label, format }) => ({
          ...acc,
          [label]:
            format !== undefined
              ? format(log)
              : String(log[id as keyof LogElementType]).trim(),
        }),
        {} as { [key: string]: string }
      )
    );

    const workSheet = XLSX.utils.json_to_sheet(rowsToExport);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "로그 기록");
    XLSX.writeFile(workBook, fileName);
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <OrderSearchWrapper>
        <FormControlWrapper>
          <form onSubmit={onSubmit}>
            <FormControl>
              <Select value={mode} onChange={handleMode}>
                {LogStatus.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <StyledTextField
              onChange={(e) => setSearch(e.target.value)}
              name="search"
              value={search}
              placeholder={"검색어를 입력하세요"}
            />
            <Button type="submit">검색</Button>
            {excelDownloadable && (
              <MuiButtonMarginLeft
                variant="outlined"
                color="primary"
                size="small"
                onClick={downloadExcel}
              >
                다운
              </MuiButtonMarginLeft>
            )}
          </form>
        </FormControlWrapper>
      </OrderSearchWrapper>
      {mode !== "today" && (
        <PastOrderSearchWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <DatePickerWrapper>
              <MobileDatePicker
                label="시작일"
                format="yyyy-MM-dd"
                value={start ? new Date(start) : null}
                slotProps={{
                  actionBar: { actions: [] },
                  day: { disableMargin: true },
                  textField: { variant: "standard", margin: "normal" },
                  toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
                }}
                closeOnSelect
                onChange={handleStartChange}
              />
              <span>~</span>
              <MobileDatePicker
                label="종료일"
                format="yyyy-MM-dd"
                value={end ? new Date(end) : null}
                onChange={handleEndChange}
                slotProps={{
                  actionBar: { actions: [] },
                  day: { disableMargin: true },
                  textField: { variant: "standard", margin: "normal" },
                  toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
                }}
                closeOnSelect
              />
            </DatePickerWrapper>
          </LocalizationProvider>
        </PastOrderSearchWrapper>
      )}
      <LogList
        rows={logs}
        count={logCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeFirst={handleChangeFirst}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default LogContainer;

const OrderSearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
  height: 5rem;
`;

const PastOrderSearchWrapper = styled(OrderSearchWrapper)`
  justify-content: center;
  padding-top: 1rem;
`;

const FormControlWrapper = styled.div`
  display: flex;
  width: auto;
`;

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const MuiButtonMarginLeft = styled(MuiButton)`
  margin-left: 1rem !important;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
