import React, { useState } from "react";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { UserState } from "../../containers/user/types";
import { DepartState } from "../../containers/department/types";
import {
  headRowsDepart,
  headRowsUser,
  headRowsProject,
} from "../../containers/corporation/types";
import { stableSort, getSorting } from "../dataTable/base";
import Paper from "../common/Paper";

interface CorporationRegistListProps {
  data: DepartState[] | UserState[];
  regist: "depart" | "user" | "project";
}

const CorporationRegistList = ({
  data,
  regist,
}: CorporationRegistListProps) => {
  const [order, setOrder] = useState("asc");
  const [departBy, setDepartBy] = React.useState<keyof DepartState>("name");
  const [userBy, setUserBy] = React.useState<keyof UserState>("username");

  /* 부서 및 프로젝트 추가 */
  const handleSortDepart = (
    event: React.MouseEvent<unknown>,
    property: keyof DepartState
  ) => {
    const isDesc = departBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setDepartBy(property);
  };

  /* 회원 추가 */
  const handleSortUser = (
    event: React.MouseEvent<unknown>,
    property: keyof UserState
  ) => {
    const isDesc = userBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setUserBy(property);
  };

  return (
    <CorpUserListPaper>
      {regist === "user" ? (
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={userBy}
            onRequestSort={handleSortUser}
            headRows={headRowsUser}
          />
          <TableBody>
            {stableSort(
              data as UserState[],
              getSorting<UserState>(order, userBy)
            ).map((user: UserState) => {
              return (
                <TableRow hover tabIndex={-1} key={user.username}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.password}</TableCell>
                  <TableCell>{user.fullname}</TableCell>
                  <TableCell>{user.department}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        // 부서, 프로젝트는 동일한 양식
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={departBy}
            onRequestSort={handleSortDepart}
            headRows={regist === "depart" ? headRowsDepart : headRowsProject}
          />
          <TableBody>
            {stableSort(
              data as DepartState[],
              getSorting<DepartState>(order, departBy)
            ).map((depart: DepartState) => {
              return (
                <TableRow hover tabIndex={-1} key={depart.name}>
                  <TableCell>{depart.name}</TableCell>
                  <TableCell>{depart.limit_budget}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </CorpUserListPaper>
  );
};
export default CorporationRegistList;

const CorpUserListPaper = styled(Paper)`
  margin-top: 1rem;
`;
