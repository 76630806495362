import { useState, useEffect } from "react";
import { useHistory, withRouter, RouteComponentProps } from "react-router-dom";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";

import InquiryDetail from "../../components/corporationInquiry/InquiryDetail";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { getInquiryInfo, updateInquiryChecker } from "../../lib/hasura/orders";
import { destructResponse } from "../../lib/hasura/common";
import { DEFAULT_INQUIRY, InquiryType } from "./types";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";

function InquiryDetailContainer({
  match,
}: RouteComponentProps<{ id: string }>) {
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(({ user: { user } }: RootStateOrAny) => user?.id); // uuid
  const inquiryId = match.params.id;

  const [inquiryData, setInquiryData] = useState<InquiryType>(DEFAULT_INQUIRY);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // mounted
  useEffect(() => {
    fetchData();
  }, [inquiryId]);

  const fetchData = async () => {
    dispatch(startGlobalLoading());
    try {
      const inquiry = await destructResponse<InquiryType>(
        "corporations_corporationinquiry_by_pk",
        () => getInquiryInfo(inquiryId)
      );

      setInquiryData(inquiry);
    } catch (err) {
      console.log(err);
      openSnackbar(
        "법인신청 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleCheckInquiry = async () => {
    // 거래방법 또는 진행현황 체크
    if (!inquiryData.payment && !inquiryData.status) {
      openSnackbar("거래방법 또는 진행현황을 등록해주세요.", true);
      return;
    }
    if (!window.confirm("해당 법인 신청 문의를 완료 처리합니다.")) return;

    dispatch(startGlobalLoading());
    try {
      const { users_user } = await destructResponse<InquiryType>(
        "update_corporations_corporationinquiry_by_pk",
        () => updateInquiryChecker(inquiryData, userId)
      );

      setInquiryData((inquiry) => ({
        ...inquiry,
        users_user,
      }));
      openSnackbar("완료 처리 되었습니다.", false);
    } catch (err) {
      console.log(err);
      openSnackbar(
        "확인 완료 처리에 실패하였습니다. 새로고침 후 다시 시도해 주세요.",
        true
      );
    } finally {
      dispatch(finishGlobalLoading());
    }
  };

  const handleInquiryOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInquiryData({
      ...inquiryData,
      [name]: value,
    });
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackbar();
    if (!error) history.goBack();
  };

  return (
    <>
      <InquiryDetail
        inquiryData={inquiryData}
        handleCheckInquiry={handleCheckInquiry}
        handleInquiryOnChange={handleInquiryOnChange}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}
export default withRouter(InquiryDetailContainer);
