import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

import {
  CorporationsType,
  headRows,
} from "../../containers/corporations/types";
import { CHANNEL } from "../../lib/constants/constants";
import { stableSort, getSorting } from "../dataTable/base";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";

interface CorporationsListProps {
  rows: CorporationsType[];
}
type SortableCorporationsRow = CorporationsType & {
  [K in Exclude<(typeof headRows)[number]["id"], keyof CorporationsType>]:
    | string
    | number
    | boolean;
};

function CorporationsList({ rows }: CorporationsListProps) {
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof SortableCorporationsRow | "">(
    ""
  );

  const handleRequestSort = (
    e: React.MouseEvent,
    property: keyof SortableCorporationsRow
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    history.push(`/corporation/${id}`);
  };

  const sortableCorporationsRows: SortableCorporationsRow[] = rows.map(
    (row) => ({
      ...row,
      phone_sort: row.phone ?? "",
      address_sort: row.address ?? "",
      registration_number_sort: row.registration_number ?? "",
      contracted_sort: row.contracted ?? "",
      username: row.users_user?.username ?? "",
    })
  );

  return (
    <Container>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(
            sortableCorporationsRows,
            getSorting<SortableCorporationsRow>(order, orderBy)
          ).map((row: CorporationsType) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                onClick={() => handleRowClick(row.id)}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{CHANNEL[row.channel]}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.users_user?.username || " "}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.registration_number}</TableCell>
                <TableCell>{row.contracted}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={row.corporate_account_enable}
                        color="primary"
                        disabled
                      />
                    }
                    id="corporate_account_enable"
                    name="corporate_account_enable"
                    label=""
                  />
                </TableCell>
                <TableCell>{row.discount_rate} %</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}

export default CorporationsList;

const Container = styled(Paper)`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
