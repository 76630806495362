import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  calculateCenterPoint,
  createMapMarker,
  getRandomRouteColor,
  ROUTE_COLORS,
  RouteColor,
} from "../../lib/map";
import { Tmapv2 } from "./t-map.types";
import { ClusterResponse } from "./types";
import ClusterMapInfoPanel from "../../containers/cluster/ClusterMapInfoPanel";
import {
  isPointGeometry,
  isPredictionEndProperty,
  isPredictionStartProperty,
} from "./predicate";

interface Props {
  clusters: ClusterResponse;
}

function ClusterTmapContainer({ clusters }: Props) {
  const entries = Object.entries(clusters);
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapElements, setMapElements] = useState<
    Record<
      string, // clusterKey
      {
        markers: Tmapv2.Marker[];
        polylines: Tmapv2.Polyline[];
        visible: boolean;
        clusterKey: string;
        distance: string | number;
        time: string;
        color: string;
        totalItem: number;
        totalBox: number;
      }
    >
  >({});

  useEffect(() => {
    const tmapV2 = window.Tmapv2;
    const points = entries.map((cluster) => {
      const [, clusterValue] = cluster;
      return clusterValue.items
        .map((item) => {
          return item.geometry;
        })
        .filter((item) => {
          return item.type === "Point";
        })
        .map((item) => {
          const [lng, lat] = item.coordinates.flat();
          return {
            latitude: lat,
            longitude: lng,
          };
        });
    });

    const centerPoint = calculateCenterPoint(points);
    if (centerPoint === null) {
      throw new Error(
        "There are not enough points. at least 2 points are required."
      );
    }
    const [startPoint, ...restPoints] = points.flat();
    const latlanBounds = new tmapV2.LatLngBounds(
      new tmapV2.LatLng(startPoint.latitude, startPoint.longitude)
    );

    restPoints.forEach((point) => {
      latlanBounds.extend(new tmapV2.LatLng(point.latitude, point.longitude));
    });

    const map = new tmapV2.Map("map_div", {
      center: new tmapV2.LatLng(centerPoint.latitude, centerPoint.longitude),
      width: "100%",
      height: "800px",
      zoom: 10,
      zoomControl: true,
      scrollwheel: true,
      httpsMode: true,
    });

    const tempMapElements: Record<
      string,
      {
        markers: Tmapv2.Marker[];
        polylines: Tmapv2.Polyline[];
        visible: boolean;
        clusterKey: string;
        distance: string | number;
        time: string;
        color: string;
        totalItem: number;
        totalBox: number;
      }
    > = {};

    const uniqueColorSet = new Set<RouteColor>();

    entries.forEach((cluster) => {
      const [clusterKey, clusterValue] = cluster;
      let color = getRandomRouteColor();
      let entryCount = 0;

      while (uniqueColorSet.has(color) && entryCount < ROUTE_COLORS.length) {
        color = getRandomRouteColor();
        entryCount++;
      }
      uniqueColorSet.add(color);

      tempMapElements[clusterKey] = {
        markers: [],
        polylines: [],
        visible: true,
        clusterKey,
        distance: clusterValue.totalDistance,
        time: String(clusterValue.totalTime),
        color: color.hex,
        totalItem: clusterValue.totalItem,
        totalBox: clusterValue.totalBox,
      };

      if (clusterValue.isMultiple) {
        clusterValue.items.forEach((info) => {
          if (isPointGeometry(info.geometry)) {
            const [lng, lat] = info.geometry.coordinates;

            const pointType = info.properties.pointType;
            const title = info.properties.viaPointName;

            const marker = new tmapV2.Marker({
              position: new tmapV2.LatLng(lat, lng),
              map: map,
              title: title,
              iconHTML: createMapMarker(pointType, color.hex),
              iconSize: new tmapV2.Size(24, 38),
            });

            tempMapElements[clusterKey].markers.push(marker);
          } else {
            const coordinates = info.geometry.coordinates;

            const points: Array<Tmapv2.LatLng> = [];
            for (const coordinate of coordinates) {
              const [lng, lat] = coordinate;
              points.push(new tmapV2.LatLng(lat, lng));
            }

            const polyLine = new tmapV2.Polyline({
              path: points,
              strokeColor: color.hex,
              strokeWeight: 6,
              map: map,
              direction: info.properties.index === "1",
              directionColor: "#000000",
            });
            tempMapElements[clusterKey].polylines.push(polyLine);
          }
        });
      } else {
        clusterValue.items.forEach((info) => {
          if (isPointGeometry(info.geometry)) {
            const [lng, lat] = info.geometry.coordinates;
            if (
              isPredictionStartProperty(info.properties) ||
              isPredictionEndProperty(info.properties)
            ) {
              const pointType = info.properties.pointType;
              const title = info.properties.clientName;

              const marker = new tmapV2.Marker({
                position: new tmapV2.LatLng(lat, lng),
                map: map,
                title: title,
                iconHTML: createMapMarker(pointType, color.hex),
                iconSize: new tmapV2.Size(24, 38),
              });
              tempMapElements[clusterKey].markers.push(marker);
            }
          } else {
            const coordinates = info.geometry.coordinates;

            const points: Array<Tmapv2.LatLng> = [];
            for (const coordinate of coordinates) {
              const [lng, lat] = coordinate;
              points.push(new tmapV2.LatLng(lat, lng));
            }

            const polyLine = new tmapV2.Polyline({
              path: points,
              strokeColor: color.hex,
              strokeWeight: 6,
              map: map,
              direction: info.properties.index === 1,
              directionColor: "#000000",
            });
            tempMapElements[clusterKey].polylines.push(polyLine);
          }
        });
      }
    });

    setMapElements(tempMapElements);
  }, []);

  const onSelectAll = useCallback(() => {
    const allSelected = Object.values(mapElements).every(
      (meta) => meta.visible
    );

    Object.values(mapElements).forEach((meta) => {
      meta.markers.forEach((marker) => {
        marker.setVisible(!allSelected);
      });
      meta.polylines.forEach((polyline) => {
        polyline.setVisible(!allSelected);
      });
    });
    setMapElements((prev) => {
      return entries.reduce<
        Record<string, (typeof mapElements)[keyof typeof mapElements]>
      >((acc, [clusterKey]) => {
        acc[clusterKey] = { ...prev[clusterKey], visible: !allSelected };
        return acc;
      }, {});
    });
  }, [mapElements, entries]);

  const onSelectCluster = useCallback(
    (clusterKey: keyof typeof mapElements) => () => {
      const meta = mapElements[clusterKey];
      const prevVisible = meta.visible;

      setMapElements((prev) => {
        const next = { ...prev };
        next[clusterKey].visible = !prevVisible;
        return next;
      });

      meta.markers.forEach((marker) => {
        marker.setVisible(!prevVisible);
      });
      meta.polylines.forEach((polyline) => {
        polyline.setVisible(!prevVisible);
      });
    },
    [mapElements]
  );

  return (
    <div>
      <MapWrapper>
        <MapContainer id="map_div" ref={mapRef} />
        <ClusterMapInfoPanel
          onSelectAll={onSelectAll}
          onSelectCluster={onSelectCluster}
          mapElements={mapElements}
        />
      </MapWrapper>
    </div>
  );
}

export default ClusterTmapContainer;

const MapWrapper = styled.div`
  position: relative;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 400px;
`;
