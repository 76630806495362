import React, { useState, useEffect } from "react";

import { RootStateOrAny, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as XLSX from "xlsx";

import CorporationRegist from "../corporation/CorporationRegist";
import { DepartState } from "../../containers/department/types";
import { UserState } from "../../containers/user/types";
import {
  CorpProps,
  CorporateProject,
} from "../../containers/corporation/types";
import {
  createDepartByCorp,
  createProject,
} from "../../lib/hasura/departments";
import { postLog } from "../../lib/hasura/users";
import { registUser } from "../../lib/api/users";
import { LOG_TYPE } from "../../lib/constants/constants";
import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { WhiteSpacePaper } from "../common/MaterialBase";
import { RowDivider } from "../common/Base";

import { CorporateDept } from "../../containers/departments/types";
import { UsersListType } from "../../containers/users/types";
import { format } from "date-fns";
import {
  DEPARTMENT_LIST_EXCEL_DOWNLOAD_COLUMNS,
  DepartmentListExcelDownloadColumnMap,
  USER_LIST_EXCEL_DOWNLOAD_COLUMNS,
  UserListExcelDownloadColumnMap,
} from "../../lib/constants/corporation-detail";

interface ManageCorporateUploadProps {
  corp: CorpProps;
  deptOpened: boolean;
  userOpened: boolean;
  projectOpened: boolean;
  allDepts: CorporateDept[];
  allUsers: UsersListType[];
}

function ManageCorporateUpload({
  corp,
  deptOpened = false,
  userOpened = false,
  projectOpened = false,
  allDepts,
  allUsers,
}: ManageCorporateUploadProps) {
  const dispatch = useDispatch();
  const [user, userAuth] = useSelector(({ user, userAuth }: RootStateOrAny) => [
    user.user,
    userAuth,
  ]);

  // 부서등록, 회원등록, 프로젝트등록
  const [departs, setDeparts] = useState<DepartState[]>([]);
  const [users, setUsers] = useState<UserState[]>([]);
  const [projects, setProjects] = useState<CorporateProject[]>([]);

  const [upload, setUpload] = useState(false);
  const [fileName, setFileName] = useState("");
  const [log, setLog] = useState(""); // 결과 로그 스택

  useEffect(() => {
    setDeparts([]);
    setUsers([]);
    setProjects([]);
    setUpload(false);
    setFileName("");
    setLog("");
  }, [deptOpened, userOpened, projectOpened]);

  const notVisible = [deptOpened, userOpened, projectOpened].every(
    (opened) => !opened
  );
  if (notVisible) return <></>;

  /**
   * @description 엑셀 업로드 공통 함수
   * @param { SetStateAction } setState 부서, 회원, 프로젝트 업로드 상태
   */
  const handleUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: React.SetStateAction<any>
  ) => {
    const files = e.target.files;

    if (files && files[0]) {
      handleFile(files[0], setState);
      setFileName(files[0].name);
      setUpload(true);
    }
  };
  const handleFile = (file: File, setState: React.SetStateAction<any>) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target && e.target.result;

      const workbook = XLSX.read(data, { type: "array" });
      const worksheetName = workbook.SheetNames[0];
      const workSheet = workbook.Sheets[worksheetName];
      const result: DepartState[] = XLSX.utils.sheet_to_json(workSheet, {
        // header: 1,
        defval: "",
        blankrows: false,
      });

      setState(result);
    };
    reader.readAsArrayBuffer(file);
  };

  /**
   * @description 엑셀 업로드 공통 함수
   * @param e
   * @param commonPromise
   * @param type
   */
  const commonSubmit = async (
    e: React.FormEvent,
    commonPromise: () => Promise<Promise<void>[]>,
    type: string
  ) => {
    e.preventDefault();
    dispatch(startGlobalLoading());
    try {
      const promise = await commonPromise();
      await Promise.allSettled(promise);

      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.MANAGE,
        `${corp.name} ${type} (업로드) 완료`,
        "법인 관리"
      );
    } catch (error) {
      setLog((prev) => (prev += `[ERROR] ${error}\n`));
    } finally {
      setLog((prev) => (prev += "진행완료"));
      dispatch(finishGlobalLoading());
    }
  };
  // 부서등록 (업로드)
  const departPromise = async () => {
    return departs.map(async (el) => {
      const requestDepart = { ...el, corporation: corp.id };
      try {
        const {
          data: { errors },
        } = await createDepartByCorp(requestDepart);
        if (errors !== undefined) throw new Error(errors[0].message);

        setLog((prev) => (prev += `[성공] 부서명: ${el.name}\n`));
      } catch (error) {
        setLog((prev) => (prev += `[실패] 부서명: ${el.name} -> ${error}\n`));
      }
    });
  };
  // 회원등록 (업로드)
  const userPromise = async () => {
    return users.map(async (el) => {
      const email = el.email;
      const employeeNumber = email?.split("@")[0] || null;
      try {
        await registUser({
          corporation_signup: true,
          username: el.username,
          password: el.password,
          passwordConfirm: el.password,
          fullname: el.fullname,
          phone: el.phone.toString(),
          email,
          employeeNumber,
          company_name: corp.name,
          department_name: el.department,
        });
        setLog((prev) => (prev += `[성공] 아이디: ${el.username}\n`));
      } catch (error) {
        setLog(
          (prev) => (prev += `[실패] 아이디: ${el.username} -> ${error}\n`)
        );
      }
    });
  };
  // 프로젝트등록 (업로드)
  const projectPromise = async () => {
    return projects.map(async (el) => {
      const requestProject = { ...el, corporation: corp.id };
      try {
        const {
          data: { errors },
        } = await createProject(requestProject);
        if (errors !== undefined) throw new Error(errors[0].message);

        setLog((prev) => (prev += `[성공] 프로젝트명: ${el.name}\n`));
      } catch (error) {
        setLog(
          (prev) => (prev += `[실패] 프로젝트명: ${el.name} -> ${error}\n`)
        );
      }
    });
  };

  const downloadDepartmentList = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const fileName = `${corp.name}_부서목록_${today}.xlsx`;
    const sortedDepts = allDepts.slice().sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    const excelData = sortedDepts.map<DepartmentListExcelDownloadColumnMap>(
      (dept) => {
        return DEPARTMENT_LIST_EXCEL_DOWNLOAD_COLUMNS.reduce<DepartmentListExcelDownloadColumnMap>(
          (acc, cur) => {
            const admin = allUsers?.find(
              (user) => user.id === dept.administrator_id
            );
            switch (cur.key) {
              case "name":
                acc[cur.label] = dept.name;
                break;
              case "userCount":
                acc[cur.label] = dept.users_users.length;
                break;
              case "administrator.username":
                acc[cur.label] = admin?.username || "";
                break;
              case "administrator.fullname":
                acc[cur.label] = admin?.fullname || "";
                break;
              case "administrator.phone":
                acc[cur.label] = admin?.phone || "";
                break;
              case "administrator.email":
                acc[cur.label] = admin?.email || "";
                break;
              case "modified":
                acc[cur.label] = format(
                  new Date(dept.modified),
                  "yyyy-MM-dd HH:mm"
                );
                break;
              default:
                break;
            }
            return acc;
          },
          {} as DepartmentListExcelDownloadColumnMap
        );
      }
    );
    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "데이터내역");
    XLSX.writeFile(workBook, fileName);
  };

  const downloadUserList = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const fileName = `${corp.name}_회원목록_${today}.xlsx`;
    const sortedUsers = allUsers.slice().sort((a, b) => {
      if (a.username < b.username) return -1;
      if (a.username > b.username) return 1;
      return 0;
    });

    const excelData = sortedUsers.map<UserListExcelDownloadColumnMap>(
      (user) => {
        return USER_LIST_EXCEL_DOWNLOAD_COLUMNS.reduce<UserListExcelDownloadColumnMap>(
          (acc, cur) => {
            switch (cur.key) {
              case "username":
                acc[cur.label] = user.username;
                break;
              case "fullname":
                acc[cur.label] = user.fullname;
                break;
              case "phone":
                acc[cur.label] = user.phone;
                break;
              case "email":
                acc[cur.label] = user.email;
                break;
              case "department.name":
                acc[cur.label] = user.corporations_department?.name || "";
                break;
              default:
                break;
            }
            return acc;
          },
          {} as UserListExcelDownloadColumnMap
        );
      }
    );
    const workSheet = XLSX.utils.json_to_sheet(excelData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "데이터내역");
    XLSX.writeFile(workBook, fileName);
  };

  return (
    <Box>
      {deptOpened && (
        <CorporationRegist
          data={departs}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setDeparts)}
          onSubmit={(e) => commonSubmit(e, departPromise, "부서등록")}
          regist="depart"
          download={downloadDepartmentList}
        />
      )}
      {userOpened && (
        <CorporationRegist
          data={users}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setUsers)}
          onSubmit={(e) => commonSubmit(e, userPromise, "회원등록")}
          regist="user"
          download={downloadUserList}
        />
      )}
      {projectOpened && (
        <CorporationRegist
          data={projects}
          upload={upload}
          fileName={fileName}
          handleUpload={(e) => handleUpload(e, setProjects)}
          onSubmit={(e) => commonSubmit(e, projectPromise, "프로젝트등록")}
          regist="project"
        />
      )}
      {log && <WhiteSpacePaper>{log}</WhiteSpacePaper>}
      <RowDivider size="3" />
    </Box>
  );
}

export default React.memo(ManageCorporateUpload);

const Box = styled.div`
  padding: 1rem 0 0;
`;
