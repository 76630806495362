import React from "react";
import styled from "styled-components";

import CircularLoading from "../common/CircularLoading";
import DateContainer from "../../containers/sendBill/DateContainer";
import ExcelButtonsContainer from "../../containers/sendBill/ExcelButtonsContainer";
import SendButtonContainer from "../../containers/sendBill/SendButtonContainer";
import DialogContainer from "../../containers/sendBill/DialogContainer";
import SenderMailContainer from "../../containers/sendBill/SenderMailContainer";
import ExtraChargeCheckContainer from "../../containers/sendBill/ExtraChargeCheckContainer";

interface SendBillProps {
  isSendingAvailable: boolean;
  date: string;
  result: { success: boolean; errorMessage: string };
  resetResult: () => void;
  postExcelBill: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading: boolean;
  extraCharge: boolean;
}

function SendBill({
  isSendingAvailable,
  date,
  result,
  resetResult,
  postExcelBill,
  loading,
  extraCharge,
}: SendBillProps) {
  return (
    <>
      {loading ? (
        <CircularLoading />
      ) : (
        <SendBillForm>
          <h1>청구서 발송</h1>
          <SenderMailContainer />
          <DateContainer />
          <p>{`이용기간 : ${date}`}</p>

          <ExtraChargeCheckContainer extraCharge={extraCharge} />
          <ExcelButtonsContainer />
          <SendButtonContainer
            isSendingAvailable={isSendingAvailable}
            postExcelBill={postExcelBill}
          />
        </SendBillForm>
      )}
      <DialogContainer result={result} resetResult={resetResult} />
    </>
  );
}

export default SendBill;

const SendBillForm = styled.form`
  margin: 1rem auto;
  text-align: center;
  width: 30%;

  p {
    margin: 5px 0;
  }
`;
