export const inquiryPayment = [
  {
    value: "CREDIT_CARD",
    label: "법인카드",
  },
  {
    value: "CORPORATE_ACCOUNT",
    label: "신용거래",
  },
];

export const inquiryStatus = [
  {
    value: "WAITING",
    label: "문의확인",
  },
  {
    value: "REQUEST",
    label: "요청확인",
  },
  {
    value: "DISCUSSING",
    label: "내부논의",
  },
  {
    value: "SUCCESS",
    label: "거래확정",
  },
  {
    value: "FAIL",
    label: "거래실패",
  },
];
