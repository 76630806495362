import React, { useState, useEffect, useReducer, useRef, useMemo } from "react";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  TextField,
  IconButton,
  InputLabel,
  Button as MoveToOrderCheckButton,
  Snackbar,
  withStyles,
} from "@material-ui/core";
import { Replay } from "@material-ui/icons";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ko from "date-fns/locale/ko";
import * as XLSX from "xlsx";

import OrderList from "../../components/orders/OrderList";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import { startGlobalLoading, finishGlobalLoading } from "../../modules/loading";
import { AdminUserAuthsType } from "../users/types";
import { destructResponse } from "../../lib/hasura/common";
import { listOrdersPeriod } from "../../lib/hasura/orders";
import { postLog } from "../../lib/hasura/users";
import { toISODateString } from "../../lib/datetime";
import {
  ADMIN_EXCEL_COLUMNS,
  DownColumns,
} from "../../lib/constants/orders/constants";
import {
  ChannelData,
  LOG_TYPE,
  STATUS,
  TargetData,
} from "../../lib/constants/constants";
import {
  InitialSearchParamsProps,
  StatusOptionType,
  OrdersOrder,
  RegistExcelOrder,
  usersUserNull,
} from "./types";

const e = new Date();
e.setHours(0, 0, 0, 0);
const s = new Date(e.getTime());
const addChannelData = ChannelData.concat({ key: "cake", label: "고바이크" });

const InitialSearchParams = {
  start: s,
  end: e,
  search: "",
  target: TargetData[1].key,
  channel: addChannelData[0].key,
  statusOptions: Object.entries(STATUS).map(([key, label]) => ({
    key,
    label,
    value: key !== "CANCELLATION",
  })),
};

function reducer(
  state: InitialSearchParamsProps,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case "START_DATE":
      return { ...state, start: action.payload };
    case "END_DATE":
      return { ...state, end: action.payload };
    case "CHANNEL":
      return { ...state, channel: action.payload };
    case "SEARCH":
      return { ...state, search: action.payload };
    case "TARGET":
      return { ...state, target: action.payload };
    case "STATUS_OPTIONS": {
      const { name, checked } = action.payload;
      return {
        ...state,
        statusOptions: state.statusOptions.map((status: StatusOptionType) => {
          if (status.key === name) return { ...status, value: checked };
          return status;
        }),
      };
    }
    case "REPLACE":
      return action.payload;
    default:
      return state;
  }
}

function OrdersContainer() {
  const dispatcher = useDispatch();
  const history = useHistory();
  const [user, userAuth, adminUserAuth] = useSelector(
    ({ user, userAuth }: RootStateOrAny) => [
      user.user,
      userAuth,
      userAuth.userAuth?.data.users_adminuserauth,
    ]
  );

  /**
   * @description 메뉴명 권한
   * @returns { string{} | undefined }
   */
  const ORDERS = useMemo(
    () =>
      adminUserAuth?.find(
        ({ menu_id }: AdminUserAuthsType) => menu_id === "ORDERS"
      ),
    [adminUserAuth]
  );

  const isAlgoquick = useMemo(() => user?.channel === "algoquick", [user]);
  const ModifySearchParams: InitialSearchParamsProps = useMemo(() => {
    return { ...InitialSearchParams, channel: user?.channel || "algoquick" };
  }, [user]);

  const [state, dispatch] = useReducer(reducer, ModifySearchParams);
  const prevState = useRef(ModifySearchParams);
  const { start, end, search, target, channel, statusOptions } = state;

  const [orders, setOrders] = useState<OrdersOrder[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  // 주문 정보 API
  const fetchOrders = async ({
    start,
    end,
    ...params
  }: InitialSearchParamsProps) => {
    dispatcher(startGlobalLoading());
    try {
      const nextDay = new Date(end.getTime());
      nextDay.setDate(nextDay.getDate() + 1);

      const result: OrdersOrder[] = await destructResponse("orders_order", () =>
        listOrdersPeriod(start.toISOString(), nextDay.toISOString(), params)
      );
      setOrders(result);
    } catch (err) {
      console.log(err);
      setOrders([]);
      openSnackbar(
        "주문 정보를 받아올 수 없습니다. 개발팀에 문의해주세요.",
        true
      );
    } finally {
      dispatcher(finishGlobalLoading());
    }
  };

  // mounted
  useEffect(() => {
    fetchOrders(state);
  }, []);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPage(0); // 페이지 초기화

    prevState.current = state;
    await fetchOrders(state);
    await postLog(
      user.id,
      userAuth.ip,
      LOG_TYPE.SEARCH,
      `${search} 주문정보 검색`.trim(),
      "주문정보"
    );
  };

  // paging
  const handleChangePage = (
    event: React.MouseEvent | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const excelDownload = () => {
    const fileName = `${toISODateString(
      prevState.current.start
    )}-${toISODateString(prevState.current.end)} 주문내역.xlsx`;

    const excelOrders = orders.map((order) => {
      const { users_user, algoquick_orders, usersUserByRelatedUserId } = order;

      const {
        corporations_corporation,
        corporations_department,
        username,
        fullname,
        phone,
      } = users_user || usersUserByRelatedUserId || usersUserNull;
      const {
        auth_code,
        corporate_credit_amt,
        discount_charge,
        payment_charge,
        vat,
        insurance_charge,
        is_custom_extra_charge,
        custom_extra_charge_log,
      } = algoquick_orders[0];

      const registOrder: OrdersOrder & RegistExcelOrder = Object.assign(order, {
        corporations_corporation,
        corporations_department,
        username,
        fullname,
        phone,
        auth_code,
        corporate_credit_amt,
        discount_charge,
        payment_charge,
        vat,
        insurance_charge,
        is_custom_extra_charge,
        custom_extra_charge_log,
      });

      return ADMIN_EXCEL_COLUMNS.reduce((acc, { format, ...cur }) => {
        if (!order) acc[cur.label] = "";
        else if (format !== undefined) acc[cur.label] = format(registOrder);
        else acc[cur.label] = registOrder[cur.key] || "";

        return acc;
      }, {} as DownColumns);
    });

    // 13000개 이상 다운로드시 메모리 부족 이슈: dense 옵션으로 해결
    const workSheet = XLSX.utils.json_to_sheet(excelOrders, { dense: true });
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "주문내역");
    XLSX.writeFile(workBook, fileName);
  };

  const excelDownloadLog = async () => {
    let sign = prompt("다운로드 사유를 5글자 이상 기재해주세요.");
    if (!sign) return;
    if (sign.trim().length < 5) return alert("5글자 이상 기재해주세요.");

    try {
      dispatcher(startGlobalLoading());
      excelDownload();
      await postLog(
        user.id,
        userAuth.ip,
        LOG_TYPE.EXCEL,
        `주문정보 다운: ${sign}`,
        "주문정보"
      );
    } catch (err) {
      console.log(err);
      openSnackbar(`다운로드에 실패하였습니다.\n${err}`, true);
    } finally {
      dispatcher(finishGlobalLoading());
    }
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <OrderSearchWrapper>
        <OrderCheckButtonArea>
          {isAlgoquick && (
            <MoveToOrderCheckButton
              onClick={() => history.push("/orders/omission")}
              variant="contained"
              color="primary"
              size="large"
            >
              누락 주문 관리
            </MoveToOrderCheckButton>
          )}
        </OrderCheckButtonArea>
        <FormControlWrapper>
          <form onSubmit={onSubmit}>
            <FormControl>
              <InputLabel id="search-target-select-label">검색 조건</InputLabel>
              <PaddingRightSelect
                labelId="search-target-select-label"
                id="search-target-select"
                value={target}
                onChange={(e) =>
                  dispatch({ type: "TARGET", payload: e.target.value })
                }
              >
                {TargetData.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </PaddingRightSelect>
            </FormControl>
            <StyledTextField
              name="search"
              value={search}
              placeholder={"검색어를 입력하세요"}
              margin="normal"
              onChange={(e) =>
                dispatch({ type: "SEARCH", payload: e.target.value })
              }
            />
            <StyledFormButton type="submit" size="large">
              검색
            </StyledFormButton>
            <StyledIconButton
              onClick={() => {
                fetchOrders(prevState.current);
              }}
            >
              <Replay />
            </StyledIconButton>
            {ORDERS?.excel && (
              <StyledFormButton
                onClick={excelDownloadLog}
                variant="outlined"
                color="primary"
                size="small"
              >
                다운
              </StyledFormButton>
            )}
          </form>
        </FormControlWrapper>
        <OrderCheckButtonArea>
          {isAlgoquick && (
            <MoveToOrderCheckButton
              onClick={() => history.push("/orders/dashboard")}
              variant="contained"
              color="primary"
              size="large"
            >
              누락/지연/주문 비교
            </MoveToOrderCheckButton>
          )}
        </OrderCheckButtonArea>
      </OrderSearchWrapper>
      <PastOrderSearchWrapper>
        <FormControl margin={"normal"}>
          <InputLabel id="channel-select-label">채널</InputLabel>
          <PaddingRightSelect
            labelId="channel-select-label"
            id="channel-select"
            value={channel}
            onChange={(e) =>
              dispatch({ type: "CHANNEL", payload: e.target.value })
            }
            disabled={!isAlgoquick}
          >
            {addChannelData.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </PaddingRightSelect>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
          <MobileDatePicker
            label="시작일"
            format="yyyy-MM-dd"
            value={start}
            slotProps={{
              actionBar: { actions: [] },
              day: { disableMargin: true },
              textField: { variant: "standard", margin: "normal" },
              toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
            }}
            closeOnSelect
            onChange={(date) => dispatch({ type: "START_DATE", payload: date })}
          />
          <MobileDatePicker
            label="종료일"
            format="yyyy-MM-dd"
            value={end}
            slotProps={{
              actionBar: { actions: [] },
              day: { disableMargin: true },
              textField: { variant: "standard", margin: "normal" },
              toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
            }}
            closeOnSelect
            onChange={(date) => dispatch({ type: "END_DATE", payload: date })}
          />
        </LocalizationProvider>
      </PastOrderSearchWrapper>
      <PastOrderSearchWrapper>
        <FormGroup row>
          {statusOptions.map(({ key, label, value }: StatusOptionType) => (
            <StyledFormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={value}
                  name={key}
                  size="small"
                  onChange={(e) => {
                    // e는 Synthetic Event로서 React가 재사용하기 위해 리셋될 수 있다.
                    // e.target이 null이 되는 것을 막는다.
                    e.persist();

                    dispatch({ type: "STATUS_OPTIONS", payload: e.target });
                  }}
                />
              }
              label={label}
            />
          ))}
        </FormGroup>
      </PastOrderSearchWrapper>

      <OrderList
        rows={orders}
        page={page}
        count={orders.length}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeFirst={handleChangeFirst}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default OrdersContainer;

const OrderSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  width: 100%;
  height: 4rem;
`;

const PastOrderSearchWrapper = styled(OrderSearchWrapper)`
  justify-content: center;
`;

const OrderCheckButtonArea = styled.div`
  display: flex;
  justify-content: space-around;
  width: 14rem;
`;

const FormControlWrapper = styled.div`
  display: flex;
  width: auto;
`;

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const PaddingRightSelect = styled(Select)`
  width: 145px;
`;

const StyledFormButton = withStyles({
  root: {
    margin: "8px 0 0 1rem",
  },
  textSizeLarge: {
    fontSize: "16px",
    fontWeight: 800,
  },
})(MoveToOrderCheckButton); // mui Button

const StyledIconButton = withStyles({
  root: {
    marginTop: "8px",
  },
})(IconButton);

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-right: 25px;
  }
`;
