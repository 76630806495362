import React from "react";
import styled from "styled-components";
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

interface tableProps {
  order: any;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent, property: any) => void;
  headRows: ReadonlyArray<any>;
}

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headRows,
}: tableProps) => {
  const createSortHandler = (el: any) => (event: React.MouseEvent) => {
    if (!el.hideSortIcon) onRequestSort(event, el.id);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((el: any) => (
          <OrderTableCell
            key={el.id}
            align={el.numeric ? "right" : "left"}
            padding={el.disablePadding ? "none" : "normal"}
            style={el.width ? { width: el.width } : undefined}
            sortDirection={orderBy === el.id ? order : false}
            size="medium"
          >
            <TableSortLabel
              direction={order}
              active={orderBy === el.id}
              hideSortIcon={el.hideSortIcon}
              onClick={createSortHandler(el)}
            >
              {el.label}
            </TableSortLabel>
          </OrderTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

const OrderTableCell = styled(TableCell)`
  font-weight: 900 !important;
  background: whitesmoke;
`;
