import React, { useCallback, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as XLSX from "xlsx";

import ExcelFileDownload from "../../lib/excel/ExcelFileDownload";
import ExcelFileUpload from "../../lib/excel/ExcelFileUpload";
import { readExcel } from "../../modules/sendBill";

function ExcelButtonsContainer() {
  const dispatch = useDispatch();

  const { isUploadingAvailable } = useSelector(
    ({ sendBill }: RootStateOrAny) => sendBill
  );
  const [fileName, setFileName] = useState("");

  const handleFile = useCallback(
    (file: File) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target && e.target.result;

        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const workSheet = workbook.Sheets[worksheetName];
        const result = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
          defval: "",
          blankrows: false,
        });

        const removedColumnData = result.slice(1);
        if (removedColumnData.length < 1) {
          return alert("데이터를 입력하지 않은 엑셀파일인지 확인해주세요!");
        }

        dispatch(readExcel(removedColumnData)); // 데이터 전송
      };
      reader.readAsArrayBuffer(file);
    },
    [dispatch]
  );

  const handleExcel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files[0]) {
        handleFile(files[0]);
        setFileName(files[0].name);
      }
    },
    [handleFile, setFileName]
  );

  function sampleDownload(e: React.MouseEvent) {
    e.preventDefault();
    const link = document.createElement("a");
    link.href =
      "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/algoquick_bill_sample_rev_210331.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <ButtonContainer>
      <ExcelFileDownload isIcon={true} size={1} onClick={sampleDownload} />
      <ExcelFileUpload
        isIcon={true}
        fileName={fileName}
        size={1}
        disabled={!isUploadingAvailable}
        onChange={handleExcel}
      />
    </ButtonContainer>
  );
}

export default ExcelButtonsContainer;

const ButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
