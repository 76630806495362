import React, { useState } from "react";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";

import { headRows, LogElementType } from "../../containers/log/types";
import { stableSort, getSorting } from "../dataTable/base";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";

interface logProps {
  rows: LogElementType[];
  count: number;
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  handleChangeFirst: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function LogList({
  rows,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeFirst,
}: logProps) {
  /* EnhancedTableHead */
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof LogElementType | "">("");
  const handleRequestSort = (event: any, property: keyof LogElementType) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const emptyRows =
    count !== 0
      ? rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
      : 0;

  return (
    <PaddingTopPaper>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(rows, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((el: LogElementType) => {
              const { id } = el;

              return (
                <AuthTableRow hover tabIndex={-1} key={id}>
                  {headRows.map((row) => (
                    <WidthLimitedTableCell
                      key={row.id}
                      align={row.numeric ? "right" : "inherit"}
                      maxWidth={row.maxWidth}
                    >
                      {row.format !== undefined
                        ? row.format(el)
                        : el[row.id as keyof LogElementType]}
                    </WidthLimitedTableCell>
                  ))}
                </AuthTableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 49 * emptyRows }}>
              <TableCell colSpan={headRows.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeFirst}
      />
    </PaddingTopPaper>
  );
}

export default LogList;

const PaddingTopPaper = styled.div`
  margin-top: 1rem;
  border-top: 2px rgba(0, 0, 0, 0.08) solid;
`;

const AuthTableRow = styled(TableRow)`
  background: ${(props) => props.color || "#ffffff"};
`;

const WidthLimitedTableCell = styled(({ maxWidth, ...rest }) => (
  <TableCell {...rest} />
))`
  ${({ maxWidth }) =>
    maxWidth && `max-width: ${(window.innerWidth * maxWidth) / 100}px;`}
`;
