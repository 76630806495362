import ko from "date-fns/locale/ko";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  LocalizationProvider,
  DateTimePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { DateTimePickerWrapper } from "../../pages/GroupOrder/style";

interface InputTimeFormProps {
  time: string;
  label: string;
  handleReceiverTime: any;
}

function InputTimeForm({
  time,
  label,
  handleReceiverTime,
}: InputTimeFormProps) {
  const initialTime = time ? new Date(time) : null;
  return (
    <DateTimePickerWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
        <MobileDateTimePicker
          value={initialTime}
          onChange={(newValue) => {
            if (newValue !== initialTime) {
              handleReceiverTime(newValue);
            }
          }}
          format="yyyy-MM-dd HH:mm"
          label={label}
          slotProps={{
            textField: {
              fullWidth: true,
            },
          }}
        />
      </LocalizationProvider>
    </DateTimePickerWrapper>
  );
}

export default InputTimeForm;
