import React from "react";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ko from "date-fns/locale/ko";

type sender = {
  emailAddress: string;
  emailTitle: string;
  emailText: string;
};
type SenderMailInput = {
  sender: sender;
  billDate: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onDateChange: (date: any) => void;
};

export default function SenderMailInput({
  sender,
  billDate,
  onChange,
  onDateChange,
}: SenderMailInput) {
  const { emailAddress, emailTitle, emailText } = sender;
  const date = billDate ? new Date(billDate) : null;

  return (
    <InputWrapper>
      <TextField
        margin="normal"
        label="발신 이메일 주소"
        placeholder="xxx@algolab.kr"
        variant="outlined"
        fullWidth
        name="emailAddress"
        value={emailAddress}
        onChange={onChange}
      />
      <TextField
        margin="normal"
        label="이메일 제목"
        placeholder="[정산단위명] XXXX 년 X월 사용내역서 입니다."
        variant="outlined"
        fullWidth
        name="emailTitle"
        value={emailTitle}
        onChange={onChange}
      />
      <TextField
        margin="normal"
        label="이메일 본문"
        placeholder="알고퀵 입니다. [정산단위명]의 XXXX 년 X월 사용내역서를 다음과 같이 첨부하였습니다~~ "
        variant="outlined"
        fullWidth
        name="emailText"
        multiline
        minRows={8}
        value={emailText}
        onChange={onChange}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
        <MobileDatePicker
          label={`정산일자`}
          format="yyyy년 MM월"
          value={date}
          slotProps={{
            actionBar: { actions: [] },
            day: { disableMargin: true },
            textField: {
              variant: "standard",
              margin: "normal",
              fullWidth: true,
            },
            toolbar: { toolbarFormat: "MM월", hidden: false },
          }}
          closeOnSelect
          onChange={onDateChange}
          views={["year", "month"]}
        />
      </LocalizationProvider>
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
`;
