import graphqlQuery from "./base";
import { CorporateProject } from "../../containers/corporation/types";
import { DepartmentData } from "../../containers/department/types";
import { ChannelMenu } from "../constants/constants";
import { getChannelQuery } from "./orders";

type CreateDepartByCorp = {
  corporation?: string;
  name: string;
  limit_budget: number;
};

/**
 * 법인관리 - 부서추가(업로드)
 * @param { null } administrator_id
 * @param { 0 } department_credit
 * @param { false } department_credit_enable
 */
export const createDepartByCorp = async (data: CreateDepartByCorp) => {
  const timeCheck = new Date().toISOString();
  return await graphqlQuery(`
    mutation {
      insert_corporations_department(
        objects: {
          created: "${timeCheck}",
          modified: "${timeCheck}",
          corporation_id: "${data.corporation}",
          name: "${data.name}",
          limit_budget: ${data.limit_budget},
          administrator_id: null,
          address_book_enable: false,
          department_credit: 0,
          department_credit_enable: false,
          users: "{}"
        }) {
        returning {
            id
        }
      }
    }
  `);
};

// 부서관리 - 부서검색
export const getDepartmentsSearch = (search: string, channel: ChannelMenu) =>
  graphqlQuery(`{
    corporations_department(
      where: {
        _and: [
          {corporations_corporation: ${getChannelQuery(channel)}},
          {_or: [
            {corporations_corporation: {name: {_ilike: "%${search}%"}}},
            {name: {_ilike: "%${search}%"}}
          ]}
        ]
      }) {
        modified
        administrator_id
        department_credit
        department_credit_enable
        id
        limit_budget
        name
        corporations_corporation {
          name
        }
        users_users {
          fullname
          email
          phone
          username
          corporations_department {
            name
          }
      }
    }
  }
  `);

/* 부서관리 CRUD */
export const getDepart = async (id: string) =>
  graphqlQuery(`{
  corporations_department_by_pk(id: ${id}) {
    id
    name
    limit_budget
    administrator_id
    address_book_enable
    department_credit
    department_credit_enable
    corporations_corporation {
      id
      name
      channel
      corporate_credit
    }
    users_users {
      fullname
      email
      phone
      username
      corporations_department {
        name
      }
    }
  }
}
`);
export const createDepart = async (data: DepartmentData) => {
  const timeCheck = new Date().toISOString();
  const administrator_id = data.administrator?.trim();
  // 부서 크레딧 "설정 금액만큼" 잔여 법인 크레딧 업데이트
  const corporateCredit = data.corporate_credit - data.department_credit;
  return await graphqlQuery(`
    mutation {
      insert_corporations_department(
        objects: {
          created: "${timeCheck}",
          modified: "${timeCheck}",
          corporation_id: "${data.corporation_id}",
          name: "${data.name}",
          limit_budget: ${data.limit_budget},
          administrator_id: ${
            administrator_id ? `"${administrator_id}"` : null
          },
          address_book_enable: ${data.address_book_enable},
          department_credit: ${data.department_credit},
          department_credit_enable: ${data.department_credit_enable},
          users: "{}"
        }) {
        returning {
            id
        }
      }
      update_corporations_corporation(
        where: {
          id: {_eq: "${data.corporation_id}"}},
          _set: {
            corporate_credit: ${corporateCredit}
          }) {
          returning {
            id
          }
        }
    }
  `);
};
export const updateDepart = async (
  data: DepartmentData,
  legacyCredit: number
) => {
  const administrator_id = data.administrator?.trim();
  // 부서크레딧 "변동 금액만큼" 잔여 법인 크레딧 업데이트
  const corporateCredit =
    data.corporate_credit - (data.department_credit - legacyCredit);
  return await graphqlQuery(`
    mutation {
      update_corporations_department(
        where: {
          id: {_eq: ${data.id}}},
          _set: {
            id: ${data.id},
            name: "${data.name}"
            limit_budget: ${data.limit_budget},
            administrator_id: ${
              administrator_id ? `"${administrator_id}"` : null
            },
            address_book_enable: ${data.address_book_enable},
            department_credit: ${data.department_credit},
            department_credit_enable: ${data.department_credit_enable},
          }) {
        returning {
          id
        }
      },
      update_corporations_corporation(
        where: {
          id: {_eq: "${data.corporation_id}"}},
          _set: {
            corporate_credit: ${corporateCredit}
          }) {
          returning {
            id
          }
        }
    }
  `);
};
export const updateCorpCredit = async (id: string) =>
  await graphqlQuery(`{
    corporations_corporation_by_pk(id: "${id}") {
      corporate_credit
    }
  }`);
export const updateNullBackupUser = async (id: string) =>
  await graphqlQuery(`
  mutation {
    update_users_backupuser(
      where: {
        department_id: {_eq: "${id}"}},
        _set: {
          department_id: null
        }) {
        returning {
          id
      }
    }
  }`);
export const deleteDepart = async (id: string) =>
  await graphqlQuery(`
  mutation {
    delete_corporations_department(where: {id: {_eq: "${id}"}}) {
      returning {
        id
      }
    }
  }`);

/* 프로젝트 관리 CRUD */
export const getProjects = async (corpId: string) => {
  return graphqlQuery(`{
    corporations_project(
      where: {corporation_id: {_eq: "${corpId}"}},
      order_by: {id: asc}
    ) {
      id
      name
      limit_budget
      corporations_corporation {
        name
      }
    }

  }`);
};
export const getProject = async (projectId: number) =>
  graphqlQuery(`{
    corporations_project(
      where: {id: {_eq: ${projectId}}},
      order_by: {id: asc}
    ) {
      id
      name
      limit_budget
    }
  }`);
export const createProject = async (data: CreateDepartByCorp) => {
  const timeCheck = new Date().toISOString();
  return await graphqlQuery(`
    mutation {
      insert_corporations_project(
        objects: {
          created: "${timeCheck}",
          modified: "${timeCheck}",
          corporation_id: "${data.corporation}",
          name: "${data.name}",
          limit_budget: ${data.limit_budget},
        }) {
        returning {
          id
        }
      }
    }
  `);
};
export const updateProject = async (data: CorporateProject) => {
  return await graphqlQuery(`
    mutation {
      update_corporations_project(
        where: {
          id: {_eq: ${data.id}}},
          _set: {
            name: "${data.name.trim()}",
            limit_budget: ${data.limit_budget},
          }) {
        returning {
          id
        }
      }
    }
  `);
};
export const deleteProject = async (id: number) => {
  return await graphqlQuery(`
  mutation {
    delete_corporations_project(where: {id: {_eq: ${id}}}) {
      returning {
        id
      }
    }
  }`);
};
