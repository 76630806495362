import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { stableSort, getSorting } from "../dataTable/base";
import {
  AuthLevels,
  AuthUserDetailType,
  authHeadRows,
  authLevelColor,
} from "../../containers/users/types";

type SortableAuthUserDetailType = AuthUserDetailType & {
  [K in Exclude<
    (typeof authHeadRows)[number]["id"],
    keyof AuthUserDetailType
  >]: string | number | boolean;
};

function UserAuthList({ users }: { users: AuthUserDetailType[] }) {
  const history = useHistory();

  /* EnhancedTableHead */
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof SortableAuthUserDetailType | "">(
    ""
  );
  const handleRequestSort = (
    event: React.MouseEvent,
    property: keyof SortableAuthUserDetailType
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const sortedUsers = useMemo((): SortableAuthUserDetailType[] => {
    return users
      .map((el: AuthUserDetailType) => ({
        ...el,
        ip_address_for_sort: el.ip_address.join(", "),
      }))
      .sort(
        (a: AuthUserDetailType, b: AuthUserDetailType) =>
          AuthLevels[a.auth_level] - AuthLevels[b.auth_level]
      );
  }, [users]);

  const handleRowClick = (id: number) => {
    history.push(`/users/auth/${id}`);
  };

  return (
    <PaddingTopPaper>
      <Table>
        <EnhancedTableHead
          headRows={authHeadRows}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(sortedUsers, getSorting(order, orderBy)).map(
            (el: AuthUserDetailType) => {
              return (
                <AuthTableRow
                  key={el.id}
                  tabIndex={-1}
                  color={authLevelColor[el.auth_level]}
                  onClick={() => handleRowClick(el.id)}
                  hover
                >
                  <TableCell>{el.username}</TableCell>
                  <TableCell>{el.ip_address?.join(", ")}</TableCell>
                  <TableCell>{el.fullname}</TableCell>
                  <TableCell>{el.corporation_name}</TableCell>
                  <TableCell>{el.department_name}</TableCell>
                  <TableCell>{el.auth_level}</TableCell>
                </AuthTableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </PaddingTopPaper>
  );
}

export default UserAuthList;

const PaddingTopPaper = styled.div`
  margin-top: 1rem;
  border-top: 2px rgba(0, 0, 0, 0.08) solid;
`;

const AuthTableRow = styled(TableRow)`
  background: ${({ color }) => color || "#fff"};
`;
