import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { headRows } from "./OrderListHeadRows";
import {
  CHANNEL,
  VEHICLE,
  STATUS,
  PAYMENTS,
} from "../../lib/constants/constants";
import {
  locationExtraCharge,
  vehicleByRiderFilter,
} from "../../lib/orders/charge";
import { formatDate } from "../../lib/datetime";
import { addressDong } from "../../lib/address";
import { stableSort, getSorting } from "../dataTable/base";
import { OrderColor, OrdersOrder } from "../../containers/orders/types";

interface OrderListProps {
  rows: OrdersOrder[];
  page: number;
  count: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent | null, page: number) => void;
  handleChangeFirst: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type SortableOrderListRowType = OrdersOrder & {
  [K in Exclude<(typeof headRows)[number]["id"], keyof OrdersOrder>]:
    | string
    | number
    | boolean;
};

function OrderList({
  rows,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeFirst,
}: OrderListProps) {
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof SortableOrderListRowType | "">(
    ""
  );
  const count = rows.length;

  const handleRequestSort = (
    event: React.MouseEvent,
    property: keyof SortableOrderListRowType
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    history.push(`/order/${id}`);
  };

  const emptyRows =
    count !== 0
      ? rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
      : 0;

  const isdOrderCheck = (number: string | null, status: string) => {
    if (number == null || number.trim() === "") {
      switch (status) {
        case "BEFORE_WAITING":
          return "승인 대기중";
        case "REJECTION":
          return "반려";
        case "CANCELLATION":
          return "취소";
        default:
          return ""; // 오류
      }
    } else return number; // 인성번호
  };

  const sortableOrderListRows: SortableOrderListRowType[] = rows.map((row) => ({
    ...row,
    isd_order_number_sort: row.isd_order_number || "",
  }));

  return (
    <>
      <PaddingTopPaper>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headRows={headRows}
          />
          <TableBody>
            {stableSort(
              sortableOrderListRows,
              getSorting<SortableOrderListRowType>(order, orderBy)
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                ({
                  id,
                  created,
                  channel,
                  isd_order_number,
                  external_isd_order_number,
                  client_name,
                  sender_name,
                  sender_jibun_address,
                  receiver_name,
                  receiver_jibun_address,
                  vehicle_type,
                  status,
                  call24_order_number,
                  payment,
                  is_isd_order,
                  rider_name,
                  location_charge,
                  extra_charge,
                }: OrdersOrder) => {
                  return (
                    <OrderTableRow
                      hover
                      tabIndex={-1}
                      key={id}
                      onClick={() => handleRowClick(id)}
                      color={OrderColor[status]}
                    >
                      <TableCell>{formatDate(created)}</TableCell>
                      <TableCell>{CHANNEL[channel]}</TableCell>
                      <TableCell>{is_isd_order ? "전화" : "웹"}</TableCell>
                      <IsdOrderCell
                        color={isdOrderCheck(isd_order_number, status)}
                      >
                        {isdOrderCheck(isd_order_number, status) || "오 류"}
                      </IsdOrderCell>
                      <TableCell>
                        {external_isd_order_number || call24_order_number}
                      </TableCell>
                      <TableCell>{client_name}</TableCell>
                      <TableCell>{addressDong(sender_jibun_address)}</TableCell>
                      <TableCell>{sender_name}</TableCell>
                      <TableCell>
                        {addressDong(receiver_jibun_address)}
                      </TableCell>
                      <TableCell>{receiver_name}</TableCell>
                      <TableCell align="right">
                        {locationExtraCharge(location_charge, extra_charge)}
                      </TableCell>
                      <TableCell>
                        {vehicleByRiderFilter(
                          VEHICLE[vehicle_type],
                          rider_name
                        )}
                      </TableCell>
                      <TableCell>{PAYMENTS[payment]}</TableCell>
                      <TableCell>{STATUS[status]}</TableCell>
                    </OrderTableRow>
                  );
                }
              )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 49 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onPageChange={(event: React.MouseEvent | null, page: number) =>
            handleChangePage(event, page)
          }
          onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFirst(event)
          }
        />
      </PaddingTopPaper>
    </>
  );
}

export default OrderList;

const OrderTableRow = styled(TableRow)`
  background: ${(props) => props.color || "palevioletred"};
`;

const IsdOrderCell = styled(TableCell)`
  color: ${(props) => (props.color ? "#000" : "red !important")};
  font-size: ${(props) => (props.color ? "0.875rem" : "1rem !important")};
  font-weight: ${(props) => (props.color ? "400" : "900 !important")};
`;

const PaddingTopPaper = styled.div`
  margin-top: 2rem;
  border-top: 2px rgba(0, 0, 0, 0.08) solid;
`;
