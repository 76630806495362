import React from "react";
import { Button } from "@material-ui/core";

interface SendButtonProps {
  isSendingAvailable: boolean;
  postExcelBill: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function SendButtonContainer({
  isSendingAvailable,
  postExcelBill,
}: SendButtonProps) {
  return (
    <>
      {isSendingAvailable && (
        <Button
          className="bill-button"
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "100%" }}
          onClick={postExcelBill}
        >
          청구서 발송진행
        </Button>
      )}
    </>
  );
}

export default SendButtonContainer;
