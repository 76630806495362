import { secondsToHMS } from "../../lib/datetime";

import { format, parse } from "date-fns";
import ko from "date-fns/locale/ko";

import {
  ClusterData,
  EndProperty,
  PointGeometry,
  SenderType,
  PredictionStartProperty,
  PredictionEndProperty,
  LineStringBreakpointProperty,
} from "./types";

import {
  isBreakpointProperty,
  isEndPointProperty,
  isLineStringBreakpointProperty,
  isPredictionEndProperty,
  isPredictionStartProperty,
  isStartPointProperty,
} from "./predicate";
import { meterToKiloMeter } from "../../lib/distance";
import { ClusterMaintableRow, ClusterSubtableRow } from "./tableColumns";

type ClusterMaintableRowByClusterId = Record<string, ClusterMaintableRow>;

export function getClusterMainTableRows(
  clusterEntries: [string, ClusterData][]
): ClusterMaintableRowByClusterId {
  const mapped = clusterEntries.reduce<ClusterMaintableRowByClusterId>(
    (acc, [clusterId, cluster], index) => {
      const no = index + 1;
      if (acc[clusterId] == undefined) {
        acc[clusterId] = {
          no: no.toString(),
          cluster: `C${no}`,
          totalCount: cluster.totalItem.toString(),
          totalBox: cluster.totalBox.toString(),
          totalTime: secondsToHMS(cluster.totalTime),
          totalDistance: `${meterToKiloMeter(cluster.totalDistance)}km`,
        };
      }
      return acc;
    },
    {}
  );

  return mapped;
}

type PointGeometryByPointIndex = Record<string, PointGeometry>;
type ClusterPointGeometriesByClusterId = Record<
  string,
  PointGeometryByPointIndex
>;
export function getClusterGeometries(
  clusterEntries: [string, ClusterData][]
): ClusterPointGeometriesByClusterId {
  return clusterEntries.reduce<ClusterPointGeometriesByClusterId>(
    (acc, [id, cluster]) => {
      acc[id] = cluster.items
        .filter((item) => item.geometry.type === "Point")
        .reduce<PointGeometryByPointIndex>((rec, cur) => {
          const geometry = cur.geometry;
          if (geometry.type !== "Point") {
            throw new Error("geometry is not Point");
          }

          rec[cur.properties.index.toString()] = geometry;
          return rec;
        }, {});

      return acc;
    },
    {}
  );
}

type PointMetaByPointIndex = Record<string, { box: number; address: string }>;
type ClusterPointMetaByClusterId = Record<string, PointMetaByPointIndex>;

export function getClusterPointAddresses(
  clusterEntries: [string, ClusterData][],
  senderInfo: SenderType
) {
  return clusterEntries.reduce<ClusterPointMetaByClusterId>(
    (acc, [id, cluster]) => {
      if (cluster.isMultiple) {
        const mapped = cluster.items
          .filter((item) => item.geometry.type === "LineString")
          .map((item) => item.properties)
          .filter(
            (
              property
            ): property is LineStringBreakpointProperty | EndProperty =>
              isLineStringBreakpointProperty(property) ||
              isEndPointProperty(property)
          )
          // LineString 위치정보를 가지는 경유지(Breakpoint)와 도착지(End) 프로퍼티의 인덱스는
          // 대상이 되는 Point 프로퍼티의 인덱스와 동일하다.
          // 도착지(LineString) 프로퍼티 인덱스는 도착지(Point) 프로퍼티 인덱스와 동일하다.
          // 경유지(LineString) 프로퍼티 인덱스는 경유(Point) 프로퍼티 인덱스와 동일하다.
          // A->B 의 경로가 있을때 경유 프로퍼티는 B를 의미한다.
          .reduce<PointMetaByPointIndex>((acc, cur) => {
            acc[cur.index] = {
              box: cur.box,
              address: cur.address,
            };
            return acc;
          }, {});

        // 시작지에 관한 box는 언제나 0개이며
        // 시작지에 관한 address는 TMap의 Response에 없기 때문에 senderInfo에서 가져온다.
        const firstIndex = cluster.items[0].properties.index;
        mapped[firstIndex] = {
          box: senderInfo.box,
          address: senderInfo.address,
        };
        acc[id] = mapped;
        return acc;
      }

      const ret: PointMetaByPointIndex = {};
      const firstPointProperty = cluster.items[0].properties;
      const lastPointProperty =
        cluster.items[cluster.items.length - 1].properties;

      if (
        isPredictionStartProperty(firstPointProperty) &&
        isPredictionEndProperty(lastPointProperty)
      ) {
        ret[firstPointProperty.index.toString()] = {
          box: 0,
          address: senderInfo.address,
        };
        ret[lastPointProperty.index.toString()] = {
          box: lastPointProperty.box,
          address: lastPointProperty.address,
        };
      }

      acc[id] = ret;
      return acc;
    },
    {}
  );
}

type CumulativeTimeByPointIndex = Record<string, number>;
type ClusterCumulativeTimeByClusterId = Record<
  string,
  CumulativeTimeByPointIndex
>;
export function getClusterEstimatedArrivalTimes(
  clusterEntries: [string, ClusterData][]
) {
  return clusterEntries.reduce<ClusterCumulativeTimeByClusterId>(
    (acc, [id, cluster]) => {
      if (cluster.isMultiple) {
        const ret = cluster.items
          .filter((item) => item.geometry.type === "LineString")
          .map((item) => item.properties)
          .filter(
            (
              property
            ): property is LineStringBreakpointProperty | EndProperty =>
              isLineStringBreakpointProperty(property) ||
              isEndPointProperty(property)
          )
          // LineString 위치 정보를 가진 경유지 포인트와 도착지 포인트에는 time이 있다.
          // time이 있는 모든 포인트들의 누적합은 곧 시작 시간으로부터 몇 초 후에 도착할 수 있는지를 나타낸다.
          .reduce<CumulativeTimeByPointIndex>(
            (rec, cur, currentIndex, properties) => {
              const index = cur.index;
              const cumulativeTime = properties
                .slice(0, currentIndex + 1)
                .reduce((acc, clusterItem) => {
                  return acc + parseInt(clusterItem.time);
                }, 0);

              if (Number.isNaN(cumulativeTime)) {
                throw new Error("cumulativeTime is NaN");
              }

              rec[index] = cumulativeTime;
              return rec;
            },
            {}
          );

        // 시작 포인트의 누적 합은 언제나 0이다.
        const first = cluster.items[0].properties;
        if (isStartPointProperty(first)) {
          ret[first.index] = 0;
        }
        acc[id] = ret;
        return acc;
      }

      // multiple이 false인 경우 시작 포인트의 totalTime 프로퍼티가 곧
      // 시작점에서 도착점까지 걸리는 시간을 의미한다.
      const ret: CumulativeTimeByPointIndex = {};
      const first = cluster.items[0].properties;
      const last = cluster.items[cluster.items.length - 1].properties;

      if (isPredictionStartProperty(first) && isPredictionEndProperty(last)) {
        ret[first.index.toString()] = 0;
        ret[last.index.toString()] = first.totalTime;
      }

      acc[id] = ret;
      return acc;
    },
    {}
  );
}

type GetSubtableRowsByClusterIdOptions = {
  clusterEntries: [string, ClusterData][];
  clusterPointAddresses: ReturnType<typeof getClusterPointAddresses>;
  clusterGeometries: ReturnType<typeof getClusterGeometries>;
  clusterEstimatedArrivalTimes: ReturnType<
    typeof getClusterEstimatedArrivalTimes
  >;
  senderInfo: SenderType;
};

type GetSubtableRowsByClusterIdReturns = Record<string, ClusterSubtableRow[]>;

export function getSubtableRowsByClusterId({
  clusterEntries,
  clusterPointAddresses,
  clusterGeometries,
  clusterEstimatedArrivalTimes,
  senderInfo,
}: GetSubtableRowsByClusterIdOptions): GetSubtableRowsByClusterIdReturns {
  const entries = clusterEntries.map(([id, cluster]) => {
    if (cluster.isMultiple) {
      const ret: ClusterSubtableRow[] = cluster.items
        .filter((item) => item.geometry.type === "Point")
        .map((item) => item.properties)
        .map((p): ClusterSubtableRow => {
          const clusterKey = `C${id}`;

          const targetAddressBoxMeta = clusterPointAddresses[id][p.index];
          const interval = clusterEstimatedArrivalTimes[id][p.index];
          const targetGeometry = clusterGeometries[id][p.index];

          if (!targetAddressBoxMeta) {
            throw new Error("targetAddressBoxMeta is undefined");
          }

          if (interval == undefined) {
            throw new Error("interval is undefined");
          }

          if (targetGeometry == undefined) {
            throw new Error("targetGeometry is undefined");
          }

          const { address, box } = targetAddressBoxMeta;
          const startTime = new Date(senderInfo.pickupRequestTime);
          startTime.setSeconds(startTime.getSeconds() + interval);

          const clientName = p.viaPointName.replace("[0] ", "");
          const completeRequestTime =
            isBreakpointProperty(p) || isEndPointProperty(p)
              ? p.completeRequestTime
              : null;

          const lowerDate =
            completeRequestTime != null
              ? parse(completeRequestTime.lower, "yyyyMMddHHmm", new Date())
              : null;

          const upperDate =
            completeRequestTime != null
              ? parse(completeRequestTime.upper, "yyyyMMddHHmm", new Date())
              : null;

          const withinTimeWindow =
            lowerDate != null &&
            upperDate != null &&
            lowerDate <= startTime &&
            upperDate >= startTime;

          const withinTimeWindowMessage = withinTimeWindow
            ? "충족"
            : lowerDate === null || upperDate === null
              ? ""
              : "미충족";

          // const withinTimeWindow =
          return {
            clusterKey,
            pointType: p.pointType,
            clientName,
            address,
            box: box.toString(),
            distance: `${meterToKiloMeter(p.distance)}km`,
            latitude: targetGeometry.coordinates[1].toString(),
            longitude: targetGeometry.coordinates[0].toString(),
            arrivalTime: format(startTime, "MM'월' dd'일' HH'시' mm'분'", {
              locale: ko,
            }),
            completeRequestTimeLower: lowerDate
              ? format(lowerDate, "MM'월' dd'일' HH'시' mm'분'", { locale: ko })
              : "", // 특정시간 이후 도착
            completeRequestTimeUpper: upperDate
              ? format(upperDate, "MM'월' dd'일' HH'시' mm'분'", { locale: ko })
              : "", // 특정시간 이전 도착
            withinTimeWindow: withinTimeWindowMessage,
          };
        });

      return { clusterId: id, rows: ret };
    }

    const ret = cluster.items
      .map((item) => item.properties)
      .filter(
        (p): p is PredictionStartProperty | PredictionEndProperty =>
          isPredictionStartProperty(p) || isPredictionEndProperty(p)
      )
      .map((p): ClusterSubtableRow => {
        const clusterKey = `C${id}`;

        const targetAddressBoxMeta = clusterPointAddresses[id][p.index];
        const interval = clusterEstimatedArrivalTimes[id][p.index];
        const targetGeometry = clusterGeometries[id][p.index];

        if (!targetAddressBoxMeta) {
          throw new Error("targetAddressBoxMeta is undefined");
        }

        if (interval == undefined) {
          throw new Error("interval is undefined");
        }

        if (!targetGeometry) {
          throw new Error("targetGeometry is undefined");
        }

        const { address, box } = targetAddressBoxMeta;

        const startTime = new Date(senderInfo.pickupRequestTime);
        startTime.setSeconds(startTime.getSeconds() + interval);

        const distance = isPredictionStartProperty(p) ? 0 : p.distance;
        const clientName = isPredictionStartProperty(p)
          ? senderInfo.clientName
          : p.clientName;

        const completeRequestTime = isPredictionEndProperty(p)
          ? p.completeRequestTime
          : null;

        const lowerDate =
          completeRequestTime != null
            ? parse(completeRequestTime.lower, "yyyyMMddHHmm", new Date())
            : null;
        const upperDate =
          completeRequestTime != null
            ? parse(completeRequestTime.upper, "yyyyMMddHHmm", new Date())
            : null;

        const withinTimeWindow =
          lowerDate != null &&
          upperDate != null &&
          lowerDate <= startTime &&
          upperDate >= startTime;

        const withinTimeWindowMessage = withinTimeWindow
          ? "충족"
          : lowerDate == null || upperDate == null
            ? ""
            : "미충족";

        return {
          clusterKey,
          pointType: p.pointType,
          clientName,
          address,
          box: box.toString(),
          distance: `${meterToKiloMeter(distance)}km`,
          latitude: targetGeometry.coordinates[1].toString(),
          longitude: targetGeometry.coordinates[0].toString(),
          arrivalTime: format(startTime, "MM'월' dd'일' HH'시' mm'분'", {
            locale: ko,
          }),
          completeRequestTimeLower: lowerDate
            ? format(lowerDate, "MM'월' dd'일' HH'시' mm'분'", { locale: ko })
            : "", // 특정시간 이후 도착
          completeRequestTimeUpper: upperDate
            ? format(upperDate, "MM'월' dd'일' HH'시' mm'분'", { locale: ko })
            : "", // 특정시간 이전 도착
          withinTimeWindow: withinTimeWindowMessage,
        };
      });

    return { clusterId: id, rows: ret };
  });

  return entries.reduce<GetSubtableRowsByClusterIdReturns>((acc, cur) => {
    acc[cur.clusterId] = cur.rows;
    return acc;
  }, {});
}
