import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";

import SendBill from "../../components/sendBill/SendBill";
import Notice from "../../components/common/Notice";
import useSnackbar from "../../hooks/useSnackbar";

import {
  POST_EXCEL,
  postExcel,
  resetPostExcelResult,
} from "../../modules/sendBill";
import { validateSendBill } from "../../lib/validate";

function SendBillContainer() {
  const dispatch = useDispatch();

  const {
    isSendingAvailable,
    date,
    result,
    excelData,
    sender,
    billDate,
    extraCharge,
  } = useSelector(({ sendBill }: RootStateOrAny) => ({
    isSendingAvailable: sendBill.isSendingAvailable,
    date: sendBill.date,
    result: sendBill.result,
    excelData: sendBill.excelData,
    sender: sendBill.sender,
    billDate: sendBill.billDate,
    extraCharge: sendBill.extraCharge,
  }));

  const { loading } = useSelector(({ loading }: RootStateOrAny) => ({
    loading: loading[POST_EXCEL] ? loading[POST_EXCEL] : false,
  }));

  const { error, message, snackbarOpen, openSnackbar, closeSnackbar } =
    useSnackbar();

  const postExcelBill = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      const errorMessage = validateSendBill(sender);
      if (errorMessage !== "") throw new Error(errorMessage);

      dispatch(
        postExcel({
          date,
          billDate,
          emailAddress: sender.emailAddress,
          emailTitle: sender.emailTitle,
          emailText: sender.emailText,
          excelData,
          extraCharge,
        })
      );
    } catch (error) {
      const message = (error as Error).message || "청구서 발송에 실패했습니다.";
      openSnackbar(message, true);
    }
  };

  const resetResult = () => {
    dispatch(resetPostExcelResult());
  };

  const handleClose = (event: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") return;
    closeSnackbar();
  };

  return (
    <>
      <SendBill
        isSendingAvailable={isSendingAvailable}
        date={date}
        result={result}
        resetResult={resetResult}
        postExcelBill={postExcelBill}
        loading={loading}
        extraCharge={extraCharge}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Notice
          variant={error ? "error" : "success"}
          message={message}
          onClose={handleClose}
        />
      </Snackbar>
    </>
  );
}

export default SendBillContainer;
