import React from "react";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { generateAddress } from "./LargeAddressCalculate";
import ExcelFileDownload from "../../lib/excel/ExcelFileDownload";
import CustomExcelUpDownload from "../../lib/excel/CustomExcelUpDownload";
import {
  GroupWrapper,
  DescriptWrapper,
  ButtonWideWrapper,
} from "../../pages/GroupOrder/style";

const description = [
  { id: 0, address: "신사동" },
  { id: 1, address: "알고퀵" },
  { id: 2, address: "석촌호수" },
  { id: 3, address: "경기도 성남시 야탑동" },
  { id: 4, address: "서울 강남구 논현동" },
];

function LargeAddressContainer() {
  const sampleDownload = (e: React.MouseEvent) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href =
      "https://algoquick-email-asset.s3.ap-northeast-2.amazonaws.com/address_search_sample_250116.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <GroupWrapper>
      <h1>EXCEL 대량주소 표준화</h1>
      <DescriptWrapper>
        <div>
          엑셀 양식을 다운받아 <strong>주소검색</strong> 란에 기입해주세요.
          <p>알고퀵 주소검색과 동일하며, 검색결과 첫번째 주소가 저장됩니다.</p>
        </div>
        <header>* 주소관련 입력텍스트 예시입니다. *</header>
        <GroupTable>
          <TableHead>
            <TableRow>
              <TableCell align={"center"}>NO.</TableCell>
              <TableCell align={"center"}>주소검색</TableCell>
              <TableCell align={"center"}>지번주소</TableCell>
              <TableCell align={"center"}>도로명주소</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {description.map((el: { id: number; address: string }) => (
              <TableRow key={el.id}>
                <TableCell align={"center"}></TableCell>
                <TableCell align={"center"}>{el.address}</TableCell>
                <TableCell align={"center"}></TableCell>
                <TableCell align={"center"}></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </GroupTable>
      </DescriptWrapper>
      <ButtonWideWrapper>
        <ExcelFileDownload isIcon={true} size={1} onClick={sampleDownload} />
        <CustomExcelUpDownload
          channel={""}
          calculateFunction={generateAddress}
        />
      </ButtonWideWrapper>
    </GroupWrapper>
  );
}

export default LargeAddressContainer;

const GroupTable = styled(Table)`
  margin-top: 0.5rem;
  border: 1px solid rgba(224, 224, 224, 1);

  thead {
    background-color: whitesmoke;
  }
`;
