type Point = {
  latitude: string | number;
  longitude: string | number;
};

export const calculateCenterPoint = (points: Point[][]) => {
  if (points.length === 0) return null;

  const allPoints = points.flat();
  if (allPoints.length === 0) return null;

  const sum = allPoints.reduce(
    (acc, point) => {
      const pointLat =
        typeof point.latitude === "string"
          ? parseFloat(point.latitude)
          : point.latitude;

      const pointLng =
        typeof point.longitude === "string"
          ? parseFloat(point.longitude)
          : point.longitude;

      return {
        lat: acc.lat + pointLat,
        lng: acc.lng + pointLng,
      };
    },
    { lat: 0, lng: 0 }
  );

  return {
    latitude: sum.lat / allPoints.length,
    longitude: sum.lng / allPoints.length,
  };
};

export type RouteColor = {
  name: string; // 색상 이름
  hex: string; // 16진수 색상 코드
  rgb: string; // RGB 색상 코드
};

export const ROUTE_COLORS: RouteColor[] = [
  { name: "주황빛 레드", hex: "#E64A19", rgb: "rgb(230, 74, 25)" },
  { name: "골드", hex: "#FFB300", rgb: "rgb(255, 179, 0)" },
  { name: "올리브 그린", hex: "#689F38", rgb: "rgb(104, 159, 56)" },
  { name: "티파니 블루", hex: "#00ACC1", rgb: "rgb(0, 172, 193)" },
  { name: "진 보라", hex: "#4A148C", rgb: "rgb(74, 20, 140)" },
  { name: "핑크 퍼플", hex: "#8E24AA", rgb: "rgb(142, 36, 170)" },
  { name: "진청색", hex: "#1A237E", rgb: "rgb(26, 35, 126)" },
  { name: "딥 그린", hex: "#1B5E20", rgb: "rgb(27, 94, 32)" },
  { name: "버건디", hex: "#880E4F", rgb: "rgb(136, 14, 79)" },
];

export function getRandomRouteColor(): RouteColor {
  const colorIndex = Math.floor(Math.random() * ROUTE_COLORS.length);
  return ROUTE_COLORS[colorIndex];
}

export const createMapMarker = (
  text: string = "A",
  bgColor: string = "#3498db",
  textColor: string = "#ffffff",
  width: number = 24,
  height: number = 38
): string => {
  // SVG 템플릿 문자열
  const svgTemplate = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" width="${width}" height="${height}">
  <!-- 마커 메인 부분 (배경색) -->
  <path 
    d="M${width / 2},0 C${width * 0.2242},0 0,${height * 0.1679} 0,${
      height * 0.375
    } C0,${height * 0.534} ${width * 0.1317},${height * 0.6702} ${
      width * 0.3233
    },${height * 0.7273} L${width / 2},${height} L${width * 0.6767},${
      height * 0.7273
    } C${width * 0.8683},${height * 0.6702} ${width},${
      height * 0.534
    } ${width},${height * 0.375} C${width},${height * 0.1679} ${
      width * 0.7758
    },0 ${width / 2},0 Z" 
    fill="${bgColor}" />
  
  <!-- 마커 안의 글자 -->
  <text 
    x="${width / 2}" 
    y="${height * 0.4}" 
    font-family="Arial, sans-serif" 
    font-size="${width * 0.6}" 
    font-weight="bold" 
    text-anchor="middle" 
    dominant-baseline="central"
    fill="${textColor}">${text}</text>
</svg>`;

  return svgTemplate.trim();
};

export const SENDER = {
  pointType: "S",
  address: "",
  box: 0,
  distance: 0,
};

export const DESTINATION = {
  pointType: "E",
};
