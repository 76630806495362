export interface PromiseFulfilledResult<T> {
  status: "fulfilled" | "rejected";
  value: T;
}

export type SearchType = {
  address: string;
  address_detail: string | null;
  address_name: string | null;
  b_code: string | null;
  id: string;
  latitude: string;
  longitude: string;
  place_name: string | null;
  road_address_name: string | null;
};

export type ExcelDataType = {
  address: string;
  address_name: string | null;
  road_address_name: string | null;
  latitude: string;
  longitude: string;
};

export type LargeAddressExcelRow = [
  No: string,
  주소검색: string,
  지번주소: string,
  도로명주소: string,
  위도: string,
  경도: string,
];

export const assertLargeAddressRow: (
  row: unknown
) => asserts row is LargeAddressExcelRow = (row) => {
  if (!Array.isArray(row)) {
    throw new TypeError(
      `Failed to assert large address row: row should be an array but it is ${row}`
    );
  }
  // if (row.length !== 6) {
  //   throw new TypeError(
  //     `Failed to assert large address row: row should have 6 elements but it has ${row.length}`
  //   );
  // }
};
