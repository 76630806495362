import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { secondsToHMS } from "../../lib/datetime";
import { meterToKiloMeter } from "../../lib/distance";
import { Tmapv2 } from "../../components/cluster/t-map.types";

interface Props {
  onSelectAll: () => void;
  onSelectCluster: (clusterKey: string) => () => void;
  mapElements: Record<
    string,
    {
      markers: Tmapv2.Marker[];
      polylines: Tmapv2.Polyline[];
      visible: boolean;
      clusterKey: string;
      distance: string | number;
      time: string;
      color: string;
      totalItem: number;
      totalBox: number;
    }
  >;
}
const ClusterMapInfoPanel = ({
  onSelectAll,
  onSelectCluster,
  mapElements,
}: Props) => {
  return (
    <InfoPanel>
      <Button
        style={{
          marginBottom: 24,
          fontWeight: 700,
        }}
        variant="outlined"
        color="primary"
        size="medium"
        onClick={onSelectAll}
      >
        {Object.values(mapElements).every((meta) => meta.visible)
          ? "모두 해제"
          : "모두 선택 ✔"}
      </Button>

      {Object.entries(mapElements).map(([clusterKey, mapMeta]) => {
        const { color, distance, time, totalItem, totalBox, visible } = mapMeta;
        return (
          <ClusterInfo
            role="button"
            key={clusterKey}
            onClick={onSelectCluster(clusterKey)}
            isActive={visible}
          >
            <ClusterHeader>
              <ColorDot color={color} />
              <p>C{clusterKey}</p>
            </ClusterHeader>
            <ClusterTimeDistance>
              <ClusterTime>{secondsToHMS(time)}</ClusterTime>
              <ClusterDistance>{meterToKiloMeter(distance)}km</ClusterDistance>
            </ClusterTimeDistance>
            <RouteDetailWrapper>
              <RouteDetail>
                <Tag bgColor="#FFA409">배송지수</Tag>
                <strong>{totalItem}</strong>
              </RouteDetail>
              <RouteDetail>
                <Tag bgColor="#2AC1BC">총 박스</Tag>
                <strong>{totalBox}개</strong>
              </RouteDetail>
            </RouteDetailWrapper>
          </ClusterInfo>
        );
      })}
    </InfoPanel>
  );
};

export default React.memo(ClusterMapInfoPanel);

const InfoPanel = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  font-size: 14px;
  min-width: 250px;
  max-height: 80%;
  overflow-y: auto;
  z-index: 1000;

  /* 스크롤바 스타일링 */
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;
const ClusterInfo = styled.div<{ isActive: boolean }>`
  margin-bottom: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid ${(props) => (props.isActive ? "#3f51b5" : "#ededed")};
  opacity: ${(props) => (props.isActive ? 1 : 0.6)};
  background-color: ${(props) => (props.isActive ? "#f8f9ff" : "#fff")};
  box-shadow: ${(props) =>
    props.isActive ? "0 2px 12px rgba(63, 81, 181, 0.15)" : "none"};
  transform: scale(${(props) => (props.isActive ? 1.02 : 1)});
  transition: all 200ms ease-out;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ClusterHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 18px;
    font-weight: 700;
    color: #2c3e50;
  }
`;

const RouteDetailWrapper = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const RouteDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4a4a4a;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Tag = styled.span<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  color: #fff;
  padding: 6px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
`;

const ClusterTimeDistance = styled.div`
  margin: 5px 0;
  display: flex;
  align-items: baseline;
  gap: 12px;
`;

const ClusterTime = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #2c3e50;
`;

const ClusterDistance = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: #666;
`;

const ColorDot = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`;
