import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import EnhancedTableHead from "../dataTable/EnhancedTableHead";
import { UsersListType, headRows } from "../../containers/users/types";
import { stableSort, getSorting } from "../dataTable/base";

import { CustomLegend } from "../common/Base";
import Paper from "../common/Paper";

interface UsersListProps {
  rows: UsersListType[];
  search?: string;
}

type SortableUserRow = UsersListType & {
  [K in Exclude<(typeof headRows)[number]["id"], keyof UsersListType>]:
    | string
    | number
    | boolean;
};

function UsersList({ rows, search }: UsersListProps) {
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState<keyof SortableUserRow | "">("");

  const handleRequestSort = (
    e: React.MouseEvent,
    property: keyof SortableUserRow
  ) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleRowClick = (id: string) => {
    history.push(`/user/${id}`);
  };

  const sortableUserRows: SortableUserRow[] = rows.map((row) => {
    return {
      ...row,
      corporation_name_sort: row.corporations_corporation?.name ?? "",
      department_name_sort: row.corporations_department?.name ?? "",
      platform_sort: row.platform.join(", "),
    };
  });

  return (
    <Container>
      <CustomLegend>회원 관리 ({rows.length})</CustomLegend>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headRows={headRows}
        />
        <TableBody>
          {stableSort(
            sortableUserRows,
            getSorting<SortableUserRow>(order, orderBy)
          ).map((row: UsersListType) => {
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                onClick={() => handleRowClick(row.id)}
              >
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.isd_c_code}</TableCell>
                <TableCell>{row.fullname}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>
                  {row.corporations_corporation?.name ?? ""}
                </TableCell>
                <TableCell>{row.corporations_department?.name ?? ""}</TableCell>
                <TableCell>{row.platform.join(", ")}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
}

export default UsersList;

const Container = styled(Paper)`
  position: relative;
  padding: 1.5rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
