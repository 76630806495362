import { format, subDays } from "date-fns";

export const getFormatDate = (date = new Date()): string => {
  return format(date, "yyyy-MM-dd");
};

export const getSubDate = (date = new Date(), days = 1): string => {
  return format(subDays(date, days), "yyyy-MM-dd"); // days만큼 차감한 날짜 출력
};

export const getFormatTime = (date = new Date()): string => {
  return format(date, "yyyy-MM-dd HH:mm");
};

export const formatDate = (time: string | null): string => {
  if (time == null || time?.trim() === "") return "";
  const date = new Date(time);
  return getFormatTime(date);
};

export const toISODateString = (date = new Date()) =>
  [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, "0"),
    String(date.getDate()).padStart(2, "0"),
  ].join("-");

// complete_request_time을 형변환하여 formatDate 적용
export const formatCompleteTime = (time: string) => {
  // 도착시간범위 [\"2022-07-15 01:00:00+00\",\"2022-07-15 01:30:00+00\")
  if (time.startsWith("[") && !time.endsWith(",)")) {
    const range = time.slice(1, time.length - 1).split(",");
    return `${formatDate(range[0])} ~ ${formatDate(range[1])}`;
  }
  // 도착시간이후 [\"2022-07-15 01:00:00+00\",)
  else if (time.startsWith("[") && time.endsWith(",)")) {
    const upper = time.slice(1, time.length - 2);
    return `${formatDate(upper)} 이후`;
  }
  // 도착시간이전 (,\"2022-07-15 01:00:00+00\")
  else if (time.startsWith("(") && time.endsWith(")")) {
    const lower = time.slice(2, time.length - 1);
    return `${formatDate(lower)} 이전`;
  } else {
    return time;
  }
};

export const secondsToHMS = (seconds: string | number) => {
  const sec = typeof seconds === "string" ? Number(seconds) : seconds;
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  // const sec = seconds % 60;

  return `${hours}시간 ${minutes}분`;
};
