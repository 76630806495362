import type { ExcelColumn } from "../../containers/corporation/types";

export const DEPARTMENT_LIST_EXCEL_DOWNLOAD_COLUMNS = [
  { key: "name", label: "부서명" },
  { key: "userCount", label: "이용자수" },
  { key: "administrator.username", label: "관리자 아이디" },
  { key: "administrator.fullname", label: "관리자 이름" },
  { key: "administrator.phone", label: "관리자 연락처" },
  { key: "administrator.email", label: "관리자 이메일" },
  { key: "modified", label: "최종수정시간" },
] as const satisfies Omit<ExcelColumn, "hide" | "default">[];

export type DepartmentListExcelDownloadColumnMap = Record<
  (typeof DEPARTMENT_LIST_EXCEL_DOWNLOAD_COLUMNS)[number]["label"],
  string | number
>;

export const USER_LIST_EXCEL_DOWNLOAD_COLUMNS = [
  { key: "username", label: "아이디" },
  { key: "department.name", label: "부서명" },
  { key: "fullname", label: "이름" },
  { key: "phone", label: "연락처" },
  { key: "email", label: "이메일" },
] as const satisfies Omit<ExcelColumn, "hide" | "default">[];

export type UserListExcelDownloadColumnMap = Record<
  (typeof USER_LIST_EXCEL_DOWNLOAD_COLUMNS)[number]["label"],
  string | number
>;
