import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FormControl,
  TextField,
  Snackbar,
  Button,
  Chip,
} from "@material-ui/core";
import ko from "date-fns/locale/ko";
import Notice from "../../components/common/Notice";
import { RowDivider } from "../../components/common/Base";
import { rangeUpdateOrder, importOrder } from "../../lib/api/orders";
import {
  WhiteSpacePaper,
  HalfInlineBlockPaper,
} from "../../components/common/MaterialBase";
import { ContainerWrapper } from "../../components/common/Base";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const OrdersOmissionContainer = (props) => {
  // 인성 주문번호 입력값
  const [inputValue, setInputValue] = useState("");

  // 확인 결과 메시지 출력(SnackBar)
  const [error, setError] = useState(false);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [message, setMessage] = useState("");

  // 주문 번호 리스트
  const [syncData, setSyncData] = useState([]);

  // 결과 로그 스택
  const [log, setLog] = useState("");

  // 날짜 관련
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  /**
   * @description 날짜 변경 함수 onChange
   * - 현재 하루로 고정
   * @param { date } datetime
   * @returns { undefined }
   */
  const handleStartChange = (date) => {
    if (Date.parse(date) <= new Date()) {
      setStart(date.toISOString().slice(0, 10));
      setEnd(date.toISOString().slice(0, 10));
    } else {
      const todayStr = new Date().toISOString().split("T")[0];
      setMessage(`날짜를 확인해주세요 ${date.toISOString().slice(0, 10)}`);
      setError(true);
      setNoticeOpen(true);
      setStart(todayStr);
      setEnd(todayStr);
    }
  };

  // const handleEndChange = date => setEnd(date.toISOString().slice(0, 10));

  useEffect(() => {
    const todayStr = new Date().toISOString().split("T")[0];
    setStart(todayStr);
    setEnd(todayStr);
  }, []);

  /**
   * @description 주문 업데이트 (날짜별)
   * - 백엔드에 날짜별 스케줄러 작동을 요청하고, 그 로그를 출력
   * @param { event } e 이벤트
   * @returns { undefined }
   */
  const handleDateRangeUpdate = (e) => {
    e.preventDefault();
    let logs = "업데이트가 진행중입니다. 새로고침이나 버튼클릭을 삼가해주세요!";
    setLog(logs);
    rangeUpdateOrder(start, end)
      .then((res) => {
        if (res.data.result) {
          setLog((logs += `\n${start} ~ ${end} 업데이트 완료되었습니다!`));
        } else {
          setLog((logs += `\n${start} ~ ${end} 완료하지 못하였습니다!`));
        }
      })
      .catch((error) =>
        setLog(
          (logs += `\n${start} ~ ${end} 완료하지 못하였습니다!
          \n${error}
          \n개발팀에게 전달해주세요!`)
        )
      );
  };

  // 키입력 처리
  const handleOnChange = (event) => {
    const trimmedText = event.target.value.trim();
    const number = trimmedText.replace(/[^0-9]/g, "");
    setInputValue(number);
  };

  // 업데이트 목록 초기화
  const handleOnClickToReset = (event) => {
    event.preventDefault();
    setSyncData([]);
    setLog("");
  };

  // 스낵바 닫힘
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNoticeOpen(false);
  };

  /**
   * @description 주문 업데이트 (인성주문 번호 리스트)
   * - 백엔드에 인성주문번호 리스트를 주문 업데이트를 요청하고, 그 로그를 출력
   * @param { event } e 이벤트
   * @returns { undefined }
   */
  const handleSyncDataUpdate = (e) => {
    e.preventDefault();
    let logs = "업데이트를 시작합니다\n";
    syncData.forEach(async (el, idx, syncData) => {
      try {
        const res = await importOrder(el.key);
        const { data } = res;
        logs += `${el.key}: ${data.contents}\n`;
        if (idx === syncData.length - 1) {
          logs += "요청하신 목록이 업데이트 완료 되었습니다.";
        }
      } catch (err) {
        logs += `${el.key}: (에러메시지 - 개발자에게 문의하세요) ${err}\n`;
      }
      setLog(logs);
    });
    controlSnackbar(true, false, setMessage, "서버에 요청 하였습니다.");
  };

  // 상태값에 따른 스낵바 제어
  const controlSnackbar = (noticeOpen, error, setMessage, msg) => {
    setNoticeOpen(noticeOpen);
    setError(error);
    setMessage(msg);
  };

  const handleDelete = (chipToDelete) => () => {
    setSyncData((syncData) =>
      syncData.filter((data) => data.key !== chipToDelete.key)
    );
  };

  /**
   * @description 사용자의 입력을 인성주문번호로 변경해주는 함수
   * - 입력값이 없거나, 9자리 아닌 것 체크, 9자리가 이상이면 9자리로 분할하여 인성주문번호 오브젝트 생성
   * - 이 오브젝트리스트는 material UI의 Chip 형태로 변경
   * @param { event } e 이벤트
   * @returns { undefined }
   */
  const handelAddSyncData = (e) => {
    e.preventDefault();
    if (inputValue === "" || inputValue.length < 9) {
      controlSnackbar(true, true, setMessage, "주문 번호를 확인해주세요");
      setInputValue("");
      return;
    }

    if (inputValue.length !== 9) {
      let updateSyncDataList = [].concat(syncData);
      for (let index = 0; index < inputValue.length; index += 9) {
        const element = inputValue.substring(index, index + 9);
        if (updateSyncDataList.length === 0) {
          updateSyncDataList.push({
            key: element,
            label: element,
          });
        } else {
          const flag = updateSyncDataList.findIndex(
            (data) => data.key === element
          );
          if (flag === -1 && element.length === 9) {
            updateSyncDataList.push({
              key: element,
              label: element,
            });
          }
        }
      }
      setSyncData(updateSyncDataList);
    } else {
      const flag = syncData.findIndex((data) => data.key === inputValue);
      if (flag === -1) {
        const updateSyncData = syncData.concat({
          key: inputValue,
          label: inputValue,
        });
        setSyncData(updateSyncData);
      }
    }
    setInputValue("");
  };

  return (
    <>
      <ContainerWrapper>
        <TextWrapper>
          <StyledTextBox>
            알고퀵 스케줄러 (날짜), 인성주문 업데이트
          </StyledTextBox>
          <NoticeDiv>
            !! 서버에 부하 및 안정성을 위해 1일로 제한합니다.(약 20~30초 소요)
          </NoticeDiv>
          <NoticeDiv>
            !! 해당 기능은 고객에게 알림톡이 전송되지 않습니다
          </NoticeDiv>
        </TextWrapper>
        <RowDivider />
        <DateWrapper>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ko}>
            <DatePickerWrapper>
              <MobileDatePicker
                label="시작일"
                format="yyyy-MM-dd"
                value={start ? new Date(start) : null}
                slotProps={{
                  actionBar: { actions: [] },
                  day: { disableMargin: true },
                  textField: { variant: "standard", margin: "normal" },
                  toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
                }}
                closeOnSelect
                onChange={handleStartChange}
              />
              <span>~</span>
              <MobileDatePicker
                label="종료일"
                format="yyyy-MM-dd"
                value={start ? new Date(start) : null}
                slotProps={{
                  actionBar: { actions: [] },
                  day: { disableMargin: true },
                  textField: { variant: "standard", margin: "normal" },
                  toolbar: { toolbarFormat: "MM월 dd일", hidden: false },
                }}
                closeOnSelect
                onChange={handleStartChange}
              />
            </DatePickerWrapper>
          </LocalizationProvider>

          <ButtonAreaDiv>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDateRangeUpdate}
            >
              업데이트
            </Button>
          </ButtonAreaDiv>
        </DateWrapper>
        <RowDivider />
        <RowDivider />
        <RowDivider />
        <RowDivider />
        <RowDivider />
        <InputWrapper>
          <FormControl margin={"normal"}>
            <form onSubmit={handelAddSyncData}>
              <StyledTextField
                type="text"
                value={inputValue}
                placeholder="인성 주문번호를 입력해 주세요."
                onChange={handleOnChange}
              />
              <ButtonAreaDiv>
                <Button type="submit" variant="contained" color="primary">
                  추가
                </Button>
              </ButtonAreaDiv>
              {syncData.length > 0 ? (
                <>
                  <ButtonAreaDiv>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSyncDataUpdate}
                    >
                      업데이트
                    </Button>
                  </ButtonAreaDiv>
                  <ButtonAreaDiv>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleOnClickToReset}
                    >
                      초기화
                    </Button>
                  </ButtonAreaDiv>
                </>
              ) : null}
            </form>
          </FormControl>
        </InputWrapper>
        {syncData.length > 0 ? (
          <HalfInlineBlockPaper>
            {syncData.map((data) => {
              return (
                <Chip
                  key={data.key}
                  label={data.label}
                  onDelete={handleDelete(data)}
                />
              );
            })}
          </HalfInlineBlockPaper>
        ) : null}
        {log && <WhiteSpacePaper>{log}</WhiteSpacePaper>}
      </ContainerWrapper>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={noticeOpen}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        key={message}
      >
        {!error ? (
          <Notice variant="success" message={message} onClose={closeSnackbar} />
        ) : (
          <Notice variant="error" message={message} onClose={closeSnackbar} />
        )}
      </Snackbar>
    </>
  );
};

export default OrdersOmissionContainer;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledTextField = styled(TextField)`
  width: 350px;
`;

const StyledTextBox = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ButtonAreaDiv = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

const DateWrapper = styled.div`
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;
`;

const NoticeDiv = styled.div`
  font-weight: 900;
  color: red;
`;

const TextWrapper = styled.div``;

const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
