import { standardAddress } from "../../lib/api/base";
import { chunk } from "../../lib/excel/chunk";
import {
  LargeAddressExcelRow,
  assertLargeAddressRow,
} from "./LargeAddressType";

export const generateAddress = async (
  _: string,
  rows: string[][]
): Promise<LargeAddressExcelRow[]> => {
  // assert for type narrowing
  const largeAddressExcelRows: LargeAddressExcelRow[] = rows.map((row) => {
    assertLargeAddressRow(row);
    return row;
  });

  const CHUNK_OFFSET = 10;

  const chunks = chunk({
    array: largeAddressExcelRows,
    chunkSize: CHUNK_OFFSET,
  });

  try {
    const standardAddressPromises = chunks.map((chunk) =>
      standardAddress(
        chunk.map(([, address]) => ({
          // transformation empty address to null is necessary based on the api
          address: address || null,
        }))
      )
    );
    const standardAddressResults = await Promise.all(standardAddressPromises);

    return standardAddressResults
      .flat()
      .map<LargeAddressExcelRow>(
        ({ inputData, address, roadAddress, latitude, longitude }, index) => [
          (index + 1).toString(), // NO.
          inputData || "", // 주소검색
          address || "", // 지번주소
          roadAddress || "", // 도로명주소
          latitude || "", // 위도
          longitude || "", // 경도
        ]
      );
  } catch (error: unknown) {
    throw new Error("주소 검색에 실패했습니다. 개발팀에 문의해주세요.");
  }
};
