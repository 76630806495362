import { http, defaultInstance } from "./instance";
import { parseError } from "./base";

export type RegistUserType = {
  corporation_signup: boolean;
  username: string;
  password: string;
  passwordConfirm: string;
  fullname: string;
  phone: string;
  email: string | null;
  employeeNumber?: string | null;
  company_name?: string;
  department_name?: string;
};

// 회원생성
export const registUser = async (user: RegistUserType) =>
  await defaultInstance.post("/user/", user).catch(parseError);

// 회원생성 (adminuserauth 권한등록)
export const postCreateUser = async (id: string) =>
  await defaultInstance
    .post("/user/auth/set", { user_id: id })
    .catch(parseError);

// 회원수정 (기존 채널과 수정된 채널이 다를경우)
export const getSyncUser = async (phone: string, channel: string) => {
  return await http
    .get("/user/sync_channel", { params: { phone, channel } })
    .catch(parseError);
};

export const checkUserName = async (username: string) =>
  await defaultInstance
    .get("/user/check_username", { params: { username } })
    .catch(parseError);

/**
 * @param {string} phone
 * @returns {Error|Promise.<AxiosResponse>}
 * - 해당 휴대폰 번호로 사용자 정보 찾을 수 없을 경우 400 에러.
 * - 단체접수시 error status가 필요하므로 메시지를 출력하는 parseError를 처리하지 않았다.
 */
export const checkPhone = (phone: string) =>
  defaultInstance.get("/user/check_phone", { params: { phone } });
