import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import SenderMailInput from "../../components/sendBill/SenderMailInput";
import { inputSenderMail, setBillDate } from "../../modules/sendBill";

export default function SenderMailContainer() {
  const dispatch = useDispatch();
  const { sender, billDate } = useSelector(({ sendBill }: RootStateOrAny) => ({
    sender: sendBill.sender,
    billDate: sendBill.billDate,
  }));

  function handleChange(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const { name, value } = event.target;
    dispatch(inputSenderMail({ name, value }));
  }

  function handleBillDate(date: Date) {
    dispatch(setBillDate(date));
  }

  return (
    <SenderMailInput
      sender={sender}
      billDate={billDate}
      onChange={handleChange}
      onDateChange={handleBillDate}
    />
  );
}
