import {
  createAPI,
  authRequest,
  injectSessionStorage,
  injectDevMasterToken,
} from "./factory";

export const http = createAPI({
  baseURL: process.env.REACT_APP_SERVER!,
  onRequest: authRequest(injectSessionStorage),
});

export const devHttp = createAPI({
  baseURL: process.env.REACT_APP_DEV_SERVER!,
  onRequest: authRequest(injectDevMasterToken),
});

export const defaultInstance = createAPI({
  baseURL: process.env.REACT_APP_SERVER!,
});
