import { format } from "date-fns";
import { DownloadChargeResponseDataElement } from "../../lib/api/orders.type";

export type UploadedClusterExcelColumn = {
  고객명: string;
  "도착지 주소": string;
  "박스 수량": number;
  "특정시간 이후도착": string;
  "특정시간 이전도착": string;
};

export type ClusterInternalData = {
  client_name: string;
  box: number;

  lower: string | null;
  upper: string | null;

  vehicleType: string;
  vehicleOption: string;

  sender: string; // 발송지 주소
  receiver: string;
};

export type ClusterColumnMappingType = {
  [K in keyof UploadedClusterExcelColumn]: keyof ClusterInternalData;
};

export const ClusterColumnMapping = {
  고객명: "client_name",
  "도착지 주소": "receiver",
  "박스 수량": "box",
  "특정시간 이후도착": "lower",
  "특정시간 이전도착": "upper",
} as const satisfies ClusterColumnMappingType;

type ClusterColumnMapping = typeof ClusterColumnMapping;

export const formatUpperLowerTime = (time: string): string | null =>
  time ? format(new Date(time), "yyyyMMddHHmm") : null;

export type ClusterInternalDataWithCharge = ClusterInternalData & {
  senderLatitude: DownloadChargeResponseDataElement["senderLatitude"];
  senderLongitude: DownloadChargeResponseDataElement["senderLongitude"];
  receiverLatitude: DownloadChargeResponseDataElement["receiverLatitude"];
  receiverLongitude: DownloadChargeResponseDataElement["receiverLongitude"];
  distance: DownloadChargeResponseDataElement["distance"];
};

export type ClusterInternalDataWithChargeAndCluster =
  ClusterInternalDataWithCharge & {
    cluster: number;
  };
